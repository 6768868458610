// src/components/PartnerSection.jsx
import React from 'react';
import styled from 'styled-components';
import {Link} from "react-router-dom";

const partners = [
    {
        name: 'LA STYLE',
        logo: require('../../assets/LA-STYLE-Logo_transparency.png'),
        width: 242,
    },
    {
        name: 'diablo',
        logo: require('../../assets/diablo_transparent.png'),
        width: 184,
    },
    {
        name: 'Los Angeles Times',
        logo: require('../../assets/layer1.png'),
        width: 320.81,
    },
    // Add more partners as needed
];

const SectionWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    width: 1280px;
    height: 338px;
    background: #F4F4F1;
`;

const Heading = styled.h1`
    position: relative;
    top: 35px;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 45px;
    height: 0px;
    color: #484949;
    text-align: center;
    letter-spacing: 0px;
`;

const SubHeading = styled.h2`
    position: relative;
    top: 104px;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 10px;
    color: #707070;
    text-align: center;
    letter-spacing: 0px;
    line-height: 14px;
`;

const PartnerWrapper = styled.div`
    position: relative;
    top: 168px;
    height: 66px;
    display: flex;
    width: 100%;
    gap: 147px;
    justify-content: center;

`;

const Partner = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const PartnerLogo = styled.img`
    width: ${(props) => props.width};
    height: auto;
`;

const TextLink = styled.a`
    position: relative;
    display: inline-block;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 10px;
    top: 89px;
    line-height: 17px;
    font-weight: 300;
    color: #707070;
    text-decoration: none;
    padding-bottom: 2px; /* To create space for the color block */
    z-index: 1; /* Ensure the text is on top */

    &:before {
        content: '';
        position: absolute;
        right: 5px;
        bottom: 0;
        width: calc(100% - 0px); /* Subtract 10px from 100% width */
        top: 8px;
        height: 5px;
        background: rgba(246, 155, 66, 0.62);
        opacity: 0;
        transition: opacity 0.3s ease;
        z-index: -1;
    }

    &:hover:before {
        opacity: 1;
    }
`;

const PartnerSection = () => {
    return (
        <SectionWrapper>
            <Heading>Our Partners</Heading>
            <TextLink href="/partner">MORE ABOUT PARTNERING ></TextLink>
            <PartnerWrapper>
                {partners.map((partner, index) => (
                    <Partner key={index}>
                        <PartnerLogo src={partner.logo} alt={partner.name} width={partner.width}/>
                    </Partner>
                ))}
            </PartnerWrapper>
        </SectionWrapper>
    );
};

export default PartnerSection;
