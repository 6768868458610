import React from 'react';
import styled from 'styled-components';

const ValuesContainer = styled.div`
    position: relative;
    width: 1280px;
  padding: 20px;
  background-color: #ffffff;
`;

const Paragraph = styled.p`
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
`;

const Title = styled.h1`
  font-size: 20px;
  color: #000;
  margin-bottom: 20px;
`;

const Link = styled.a`
  color: #1155cc;
  text-decoration: underline;
`;

const Values = () => {
  return (
    <ValuesContainer>
      <Title>Core Values</Title>
      <Paragraph>
        This means we foster difficult conversations with care, offer our members <Link href="https://www.google.com/url?q=https://www.facebook.com/groups/593340724526509&sa=D&source=editors&ust=1719980949010678&usg=AOvVaw1HuRFs51t9Ae8SSI65tgS5">social justice educational materials</Link>, scripts and guidance, and maintain our private forums as ‘<Link href="https://www.google.com/url?q=https://www.naspa.org/files/dmfile/Policy_and_Practice_No_2_Safe_Brave_Spaces.pdf&sa=D&source=editors&ust=1719980949010945&usg=AOvVaw0Xz_Zdg5-HY7FIqBvnSOZD">brave spaces</Link>’ where our most marginalized members feel safe to speak up - and simply scroll without fearing unmoderated harm in their feed.
      </Paragraph>
      <Paragraph>
        Our aim is to provide a mom community more vast in its resources and power to uplift and support moms than any paid membership we’ve seen, while keeping the resources as accessible to as many who need it as possible.
      </Paragraph>
    </ValuesContainer>
  );
};

export default Values;
