import React from 'react';
import styled from 'styled-components';

const FinancialSupportContainer = styled.div`
    position: relative;
    width: 1280px;
  padding: 20px;
  background-color: #f8f7f6;
`;

const Paragraph = styled.p`
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
`;

const Title = styled.h2`
  font-size: 20px;
  color: #000;
  margin-bottom: 20px;
`;

const FinancialSupport = () => {
  return (
    <FinancialSupportContainer>
      <Title>WAYS TO PROVIDE FINANCIAL SUPPORT</Title>
      <Paragraph>
        While we deliberately don’t charge a mandatory membership fee, there are hard costs to providing the resources we do, and joining as a paid member is the absolute best way to support our community uplift - and keep it free for those who can’t afford to contribute.
      </Paragraph>
      <Paragraph>
        We have a pay-what-you-can model, and are incredibly grateful for any contribution:
      </Paragraph>
      <Paragraph>One-time contribution: (Any amount accepted - with a $2 minimum because of processing fees)</Paragraph>
      <Paragraph>Monthly Membership Subscription: ($5/mo suggested, any amount appreciated, with a $2/mo minimum because of processing fees)</Paragraph>
      <Paragraph>Annual Membership Subscription: ($60/year suggested, any amount appreciated with a $2/year minimum because of processing fees)</Paragraph>
    </FinancialSupportContainer>
  );
};

export default FinancialSupport;
