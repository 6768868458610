// src/components/ContactPage.jsx

import React from 'react';
import styled from 'styled-components';

const ContactContainer = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  font-family: 'Nunito Sans', sans-serif;
  color: #333;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #f69b42;
  text-align: center;
  margin-bottom: 20px;
`;

const Subtitle = styled.h2`
  font-size: 1.75rem;
  color: #33475b;
  margin-top: 30px;
  margin-bottom: 15px;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Label = styled.label`
  font-size: 1rem;
  font-weight: 600;
  color: #33475b;
`;

const Input = styled.input`
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const TextArea = styled.textarea`
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  resize: vertical;
  min-height: 100px;
`;

const SubmitButton = styled.button`
  padding: 15px;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  background-color: #f69b42;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e58935;
  }
`;

const ContactPage = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
  };

  return (
    <ContactContainer>
      <Title>Contact Us</Title>
      <Subtitle>We'd love to hear from you!</Subtitle>
      <Form onSubmit={handleSubmit}>
        <Label htmlFor="name">Name</Label>
        <Input type="text" id="name" name="name" required />

        <Label htmlFor="email">Email</Label>
        <Input type="email" id="email" name="email" required />

        <Label htmlFor="subject">Subject</Label>
        <Input type="text" id="subject" name="subject" required />

        <Label htmlFor="message">Message</Label>
        <TextArea id="message" name="message" required />

        <SubmitButton type="submit">Send Message</SubmitButton>
      </Form>
    </ContactContainer>
  );
};

export default ContactPage;
