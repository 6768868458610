import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    width: 1280px;
    margin: auto;
    margin-top: 50px;
`;

const Header = styled.header`
    margin-bottom: 20px;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

const FormGroup = styled.div`
    margin-bottom: 15px;
`;

const Label = styled.label`
    font-weight: bold;
`;

const Input = styled.input`
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
`;

const TextArea = styled.textarea`
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
`;

const Select = styled.select`
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
`;

const Button = styled.button`
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background-color: #0056b3;
    }
`;

const ApplyForm = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        email: '',
        phone1: '',
        phone2: '',
        phone3: '',
        companyName: '',
        title: '',
        website: '',
        fbFirstName: '',
        fbLastName: '',
        fbProfileLink: '',
        fbBusinessLink: '',
        igHandle: '',
        referral: '',
        aboutYou: '',
        clubExperience: '',
        benefits: [],
        membership: '12 Month Membership $297 USD'
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setFormData(prevState => ({
                ...prevState,
                benefits: checked
                    ? [...prevState.benefits, value]
                    : prevState.benefits.filter(benefit => benefit !== value)
            }));
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic
        console.log('Form Data Submitted:', formData);
    };

    return (
        <Container>
            <h1 id="logo">
                <a href="https://www.wufoo.com/?utm_source=form-header&utm_medium=logo" target="_blank" rel="noopener noreferrer" title="Powered by Wufoo">Powered by Clubhouse</a>
            </h1>
            <Form onSubmit={handleSubmit}>
                <Header>
                    <h2>Apply for The Club 2021</h2>
                    <div>***PLEASE COMPLETE ONLY ON DESKTOP - NOT MOBILE***<br/>
                        To apply, complete the information below. All your information is kept confidential. <br/>
                        As soon as your application is processed, we'll reach out to help you set up your profile on The M List and get you welcomed to our private Facebook Group. <br/>
                        Looking forward to getting to know you!
                    </div>
                </Header>
                <FormGroup>
                    <Label htmlFor="firstName">Name <span className="text-danger">*</span></Label>
                    <div className="form-row">
                        <div className="col">
                            <Input id="firstName" name="firstName" type="text" placeholder="First" required onChange={handleChange} />
                        </div>
                        <div className="col">
                            <Input id="lastName" name="lastName" type="text" placeholder="Last" required onChange={handleChange} />
                        </div>
                    </div>
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="address1">Address</Label>
                    <Input id="address1" name="address1" type="text" placeholder="Street Address" onChange={handleChange} />
                    <Input id="address2" name="address2" type="text" placeholder="Address Line 2" onChange={handleChange} />
                    <div className="form-row">
                        <div className="col">
                            <Input id="city" name="city" type="text" placeholder="City" onChange={handleChange} />
                        </div>
                        <div className="col">
                            <Input id="state" name="state" type="text" placeholder="State / Province / Region" onChange={handleChange} />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col">
                            <Input id="zip" name="zip" type="text" placeholder="Postal / Zip Code" maxLength="15" onChange={handleChange} />
                        </div>
                        <div className="col">
                            <Select id="country" name="country" onChange={handleChange}>
                                <option value="" selected></option>
                                <option value="United States">United States</option>
                                <option value="United Kingdom">United Kingdom</option>
                                {/* Add other countries as needed */}
                            </Select>
                        </div>
                    </div>
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="email">Email <span className="text-danger">*</span></Label>
                    <Input id="email" name="email" type="email" required onChange={handleChange} />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="phone1">Phone Number</Label>
                    <div className="form-row">
                        <div className="col">
                            <Input id="phone1" name="phone1" type="tel" placeholder="###" maxLength="3" onChange={handleChange} />
                        </div>
                        <div className="col">
                            <Input id="phone2" name="phone2" type="tel" placeholder="###" maxLength="3" onChange={handleChange} />
                        </div>
                        <div className="col">
                            <Input id="phone3" name="phone3" type="tel" placeholder="####" maxLength="4" onChange={handleChange} />
                        </div>
                    </div>
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="companyName">Company Name <span className="text-danger">*</span></Label>
                    <Input id="companyName" name="companyName" type="text" required onChange={handleChange} />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="title">Your Title <span className="text-danger">*</span></Label>
                    <Input id="title" name="title" type="text" required onChange={handleChange} />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="website">Website <span className="text-danger">*</span></Label>
                    <Input id="website" name="website" type="text" required onChange={handleChange} />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="fbFirstName">Your Personal Name as it appears on Facebook <span className="text-danger">*</span></Label>
                    <div className="form-row">
                        <div className="col">
                            <Input id="fbFirstName" name="fbFirstName" type="text" placeholder="First" required onChange={handleChange} />
                        </div>
                        <div className="col">
                            <Input id="fbLastName" name="fbLastName" type="text" placeholder="Last" required onChange={handleChange} />
                        </div>
                    </div>
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="fbProfileLink">Personal Profile Link on Facebook</Label>
                    <Input id="fbProfileLink" name="fbProfileLink" type="text" onChange={handleChange} />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="fbBusinessLink">Best Business Link on Facebook (Page or Group or N/A)</Label>
                    <Input id="fbBusinessLink" name="fbBusinessLink" type="text" onChange={handleChange} />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="igHandle">IG Handle</Label>
                    <Input id="igHandle" name="igHandle" type="text" onChange={handleChange} />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="referral">How did you hear about us? (Referred by?)</Label>
                    <Input id="referral" name="referral" type="text" onChange={handleChange} />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="aboutYou">Please tell us about yourself and what you're up to. <span className="text-danger">*</span></Label>
                    <TextArea id="aboutYou" name="aboutYou" rows="4" required onChange={handleChange}></TextArea>
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="clubExperience">What would you most like to gain from The Club experience? <span className="text-danger">*</span></Label>
                    <TextArea id="clubExperience" name="clubExperience" rows="4" required onChange={handleChange}></TextArea>
                </FormGroup>
                <FormGroup>
                    <Label>Check the Club benefits that excite you most. <span className="text-danger">*</span></Label>
                    <div className="form-check">
                        <input id="benefit1" name="benefits" type="checkbox" value="Online Community (Access to Our Private Facebook Group)" onChange={handleChange} />
                        <label className="form-check-label" htmlFor="benefit1">Online Community (Access to Our Private Facebook Group)</label>
                    </div>
                    <div className="form-check">
                        <input id="benefit2" name="benefits" type="checkbox" value="Weekly Business Topics & Personal Growth Posts" onChange={handleChange} />
                        <label className="form-check-label" htmlFor="benefit2">Weekly Business Topics & Personal Growth Posts</label>
                    </div>
                    <div className="form-check">
                        <input id="benefit3" name="benefits" type="checkbox" value="Video Chats with M (Business Q&A + Pep Talks)" onChange={handleChange} />
                        <label className="form-check-label" htmlFor="benefit3">Video Chats with M (Business Q&A + Pep Talks)</label>
                    </div>
                    <div className="form-check">
                        <input id="benefit4" name="benefits" type="checkbox" value="Access to The M List Membership Directory" onChange={handleChange} />
                        <label className="form-check-label" htmlFor="benefit4">Access to The M List Membership Directory</label>
                    </div>
                    <div className="form-check">
                        <input id="benefit5" name="benefits" type="checkbox" value="Opportunities to Promote your Business" onChange={handleChange} />
                        <label className="form-check-label" htmlFor="benefit5">Opportunities to Promote your Business</label>
                    </div>
                    <div className="form-check">
                        <input id="benefit6" name="benefits" type="checkbox" value="Leadership Opportunities" onChange={handleChange} />
                        <label className="form-check-label" htmlFor="benefit6">Leadership Opportunities</label>
                    </div>
                </FormGroup>
                <FormGroup>
                    <Label>Annual Membership Subscription</Label>
                    <div className="form-check">
                        <input id="membership1" name="membership" type="radio" value="12 Month Membership $297 USD" checked onChange={handleChange} />
                        <label className="form-check-label" htmlFor="membership1">12 Month Membership $297 USD</label>
                    </div>
                </FormGroup>
                <FormGroup className="text-center">
                    <Button type="submit">Submit</Button>
                </FormGroup>
            </Form>
        </Container>
    );
};

export default ApplyForm;
