import React from "react";
import "./style.css";
import styled from "styled-components";

const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    min-height: 100vh;
`;

const Header = styled.div`
    width: 100%;


    h1 {
        font-family: 'Nunito Sans', sans-serif;
        font-size: 50px;
        color: #707070;
        text-align: center;
        margin-bottom: 20px;
    }
`;

const About = () => {
    return (
        <SectionContainer>
            <Header>
                <h1>Who is The Mamahood?</h1>
            </Header>

        </SectionContainer>
    );
};
export default About;
