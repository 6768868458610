// src/components/CustomBlocks.jsx

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const BlockContainer = styled.div`
  margin: 0;
  border: 4px none black;
  border-radius: 4px;
  padding: 20px;
  background-color: #000000;

  @media (min-width: 768px) {
    padding: 30px;
    margin-top: 30px;
  }

  @media (max-width: 767px) {
    text-align: center;
  }
`;

const FeatureImage = styled.img`
  width: 150px;
  border-radius: 4px;
`;

const FeatureText = styled.div`
  p {
    font-size: 16px;
    font-weight: 400;
    color: #000;
  }

  strong {
    font-size: 16px;
  }
`;

const SectionContainer = styled.div`
  background-color: #ebebeb;
  padding-top: 30px;
  padding-bottom: 30px;

  @media (min-width: 768px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .container {
    max-width: calc(1260px + 10px + 10px);
    padding: 0 10px;

    @media (min-width: 768px) {
      max-width: calc(1260px + 40px + 40px);
      padding: 0 40px;
    }
  }

  .row {
    align-items: stretch !important;
  }
`;

const Button = styled(Link)`
  display: inline-block;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  margin-top: 1rem;
  color: #fcd77c;
  border: 1px solid #fcd77c;
  border-radius: 4px;
  background-color: transparent;

  &:hover {
    background-color: #fcd77c;
    color: #000;
  }
`;

const TextBlock = styled.div`
  padding: 20px;
  border: 4px none black;
  border-radius: 4px;
  background-color: #fff;

  @media (min-width: 768px) {
    padding: 30px;
  }

  @media (max-width: 767px) {
    text-align: center;
  }
`;

const TheMListContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`;

const CustomBlocks = () => {
  return (
    <TheMListContainer>
      <BlockContainer className="aos-init" data-aos="none" data-aos-delay="0" data-aos-duration="0">
        <div>
          <FeatureImage src="/static/img/theclub/bT69OTQTRpeKZteKrfZz_Itzel.png" alt="Placeholder Image" />
          <FeatureText>
            <p>
              <strong>
                In the 10 years I’ve been in businesses, The Club was one of the best investments I’ve ever made. It
                gave me the opportunity to collaborate with a community of ambitious, talented, generous, and all
                around fabulous women, and there was always someone who could help me out when I got stuck. The Club
                even gave me access to a targeted audience of thousands of folks who were in the market for my
                services. And to top it all off, I made some really great friends. If you want to grow your business in
                a way that reconnects you to the love and joy that inspired you to start it in the first place, join The
                Club.
              </strong>
            </p>
            <p>
              <strong>~Itzel Hayward, Attuned Living</strong>
            </p>
          </FeatureText>
        </div>
      </BlockContainer>

      <BlockContainer className="aos-init" data-aos="none" data-aos-delay="0" data-aos-duration="0">
        <div>
          <FeatureImage src="/static/img/theclub/Y2cA4FgoR9Wddzk0nOgR_Sydney.png" alt="Placeholder Image" />
          <FeatureText>
            <p>
              In my first year as a Club member, I collaborated with 50+ businesses. I hosted pop-up parties, included
              Club products in my shop inventory and swag bags. If there was anything at all I was thinking of doing - I
              did it in partnership with a Clubber. I formed lasting business partnerships and even co-launched a
              membership with other Clubbers. If you want to 10x your friendships and biz network with incredible women,
              join us!
              <strong> ~Sydney James, Stay</strong>
            </p>
          </FeatureText>
        </div>
      </BlockContainer>

      <SectionContainer>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <BlockContainer className="aos-init" data-aos="fade-left" data-aos-delay="0" data-aos-duration="1000">
              <div>
                <FeatureImage src="/static/img/theclub/0ZmAtHVhQWquL7OWWfSa_2.png" alt="Placeholder Image" />
              </div>
            </BlockContainer>

            <BlockContainer className="aos-init" data-aos="fade-right" data-aos-delay="0" data-aos-duration="1000">
              <div>
                <FeatureImage src="/static/img/theclub/feature.png" alt="Placeholder Image" />
                <FeatureText>
                  <h1>
                    <span style={{ fontFamily: "'Dancing Script', cursive", color: "#293039" }}>
                      Women go further faster together - we all know this.
                    </span>
                  </h1>
                  <p>
                    <strong>But what if you don't have your super-crew assembled?&nbsp;</strong>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      You’ve taken the leap and gone for it - you’re your own boss. WOOT!
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      But you crave more connection with women who think like you do:&nbsp;
                    </span>
                  </p>
                  <ul>
                    <li style={{ fontWeight: 400, textAlign: "left" }}>
                      <span style={{ fontWeight: 400 }}>Inspired action-takers,&nbsp;</span>
                    </li>
                    <li style={{ fontWeight: 400, textAlign: "left" }}>
                      <span style={{ fontWeight: 400 }}>go-getters, creators,&nbsp;</span>
                    </li>
                    <li style={{ fontWeight: 400, textAlign: "left" }}>
                      <span style={{ fontWeight: 400 }}>makers,&nbsp;</span>
                    </li>
                    <li style={{ fontWeight: 400, textAlign: "left" }}>
                      <span style={{ fontWeight: 400 }}>dreamers who are ‘do-ers’, and&nbsp;</span>
                    </li>
                    <li style={{ fontWeight: 400, textAlign: "left" }}>
                      <span style={{ fontWeight: 400 }}>
                        world-changing leaders who get what it’s like to go for it.&nbsp;
                      </span>
                    </li>
                  </ul>
                  <p>
                    <span style={{ fontWeight: 400 }}>I see you. You need some more biz-besties!&nbsp;</span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>(Who doesn’t?!)&nbsp;</span>
                  </p>
                </FeatureText>

                <Button to="/resource_redirect/landing_pages/1862632">TELL ME MORE!</Button>
              </div>
            </BlockContainer>
          </div>
        </div>
      </SectionContainer>

      <SectionContainer>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <TextBlock className="aos-init" data-aos="none" data-aos-delay="0" data-aos-duration="0">
              <h2>
                <span style={{ fontFamily: "'Dancing Script', cursive" }}>Featured&nbsp;Blog Posts</span>
              </h2>
            </TextBlock>

            <TextBlock className="aos-init" data-aos="none" data-aos-delay="0" data-aos-duration="0">
              <div>
                <FeatureImage src="/static/img/theclub/USgos6jXTNWiKe9wm8Db_ClubhouseBlog2.png" alt="Placeholder Image" />
                <FeatureText>
                  <h4>THE CLUB + CLUBHOUSE?</h4>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      The Club’s URL is quite obviously TheClubhouse.io. And though I’ve owned it for years, there’s a
                      new app called Clubhouse -&nbsp;no “the” - that’s blowing&nbsp;up.
                    </span>
                  </p>
                </FeatureText>
                <Button to="https://the-club.mykajabi.com/blog/the-club-clubhouse">Read More</Button>
              </div>
            </TextBlock>
          </div>
        </div>
      </SectionContainer>
    </TheMListContainer>
  );
};

export default CustomBlocks;
