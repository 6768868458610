// src/components/FooterSection.jsx
import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import NewsletterForm from './NewsletterForm'; // Import the NewsletterForm component
import {FaFacebookF, FaInstagram, FaLinkedinIn} from 'react-icons/fa';

const SocialIcon = styled.a`
    color: #e85f45;
    font-size: 18.9px;
    text-decoration: none;

    &:hover {
        color: #e85f45;
    }
`;

const TextLink = styled(Link)`
    position: relative;
    display: inline-block;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    line-height: 17px;
    font-weight: 300;
    color: #707070;
    text-decoration: none;
    padding-bottom: 2px; /* To create space for the color block */
    z-index: 1; /* Ensure the text is on top */

    &:before {
        content: '';
        position: absolute;
        right: 5px;
        bottom: 0;
        width: calc(100% - 0px); /* Subtract 10px from 100% width */
        top: 7px;
        height: 10px;
        background: rgba(246, 155, 66, 0.62);
        opacity: 0;
        transition: opacity 0.3s ease;
        z-index: -1;
    }

    &:hover:before {
        opacity: 1;
    }
`;

const FooterWrapper = styled.footer`
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1280px;
    height: 369px;
`;

const LeftSection = styled.div`
    display: flex;
    position: relative;
    top: 13px;
    left: 91.65px;
    flex-direction: row;
    gap: 109px; /* Adjust gap between the two link groups as needed */

`;

const Links = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const Linked = styled.a`
    font-family: 'Nunito Sans', sans-serif;
    font-size: 11px;
    color: #707070;
    margin-bottom: 5px;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

const CenterSection = styled.div`
    display: flex;
    position: relative;
    top: 16px;
    left: 18px;
    flex-direction: column;
    text-align: center;
`;

const Logo = styled.img`
    margin-bottom: 20px;
    left: 565px;
    width: 150px;
    height: 145px;
`;

const Copyright = styled.p`
    position: relative;
    top: 15px;
    font-family: 'EB Garamond', serif;
    font-size: 12px;
    color: #484949;
`;

const RightSection = styled.div`
    position: relative;
    display: flex;
    top: 35px;
    left: -121px;
    width: 348px;
    flex-direction: column;
    text-align: center;
`;

const NewsletterTitle = styled.p`
    font-family: 'Oswald', sans-serif;
    font-size: 18px;
    color: #707070;
    margin-bottom: 20px;
    text-align: left;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Input = styled.input`
    border: 1px solid #707070;
    padding: 5px;
    margin-bottom: 10px;
    width: 200px;

    &:nth-child(2) {
        margin-right: 10px;
    }
`;

const SendButton = styled.button`
    background-color: #39A0AC;
    color: #fff;
    padding: 5px 10px;
    border: none;
    cursor: pointer;

    &:hover {
        background-color: #2d8090;
    }
`;

const SocialLinks = styled.div`
    position: relative;
    display: flex;
    left: 282px;
    justify-content: left;
    margin-top: 20px;
    gap: 19px;

`;

const SocialLink = styled.a`
    display: flex;
    margin: 0 5px;
    color: #e85f45;
    font-size: 24px;
    text-decoration: none;

    &:hover {
        color: #c75139;
    }
`;

const DropDownItemContainer = styled.div`
    display: flex;
    position: relative;
`;

const Footer = ({currentPage}) => {
    return (
        <FooterWrapper>
            <LeftSection>
                <Links>
                    <DropDownItemContainer><TextLink to="/about"
                                                     active={currentPage === '/about'}>About</TextLink></DropDownItemContainer>
                    <DropDownItemContainer><TextLink to="/join"
                                                     active={currentPage === '/join'}>Join</TextLink></DropDownItemContainer>
                    <DropDownItemContainer><TextLink to="/community"
                                                     active={currentPage === '/community'}>Community</TextLink></DropDownItemContainer>
                    <DropDownItemContainer><TextLink to="/inspiration"
                                                     active={currentPage === '/inspiration'}>Inspiration</TextLink></DropDownItemContainer>

                    <DropDownItemContainer><TextLink to="/the-mlist" active={currentPage === '/mlist'}>The M
                        List</TextLink></DropDownItemContainer>
                    <DropDownItemContainer><TextLink to="/support" active={currentPage === '/mlist'}>Support</TextLink></DropDownItemContainer>
                </Links>
                <Links>
                    <DropDownItemContainer><TextLink to="/partner" active={currentPage === '/partner'}>Partner with
                        Us</TextLink></DropDownItemContainer>
                    <DropDownItemContainer><TextLink to="/theclub" active={currentPage === '/club'}>The
                        Club</TextLink></DropDownItemContainer>
                    <DropDownItemContainer><TextLink to="/sitemap"
                                                     active={currentPage === '/sitemap'}>Sitemap</TextLink></DropDownItemContainer>
                    <DropDownItemContainer><TextLink to="/privacy"
                                                     active={currentPage === '/privacy'}>Privacy</TextLink></DropDownItemContainer>
                    <DropDownItemContainer><TextLink to="/policies"
                                                     active={currentPage === '/policies'}>Policies</TextLink></DropDownItemContainer>
                    <DropDownItemContainer><TextLink to="/contact"
                                                     active={currentPage === '/contact'}>Contact</TextLink></DropDownItemContainer>
                </Links>
            </LeftSection>
            <CenterSection>
                <Logo src="../../static/img/component-3-8.png" alt="The Mamahood Logo"/>
                <Copyright>© 2024 The Mamahood</Copyright>
            </CenterSection>
            <RightSection>
                <NewsletterTitle>Sign Up For Our Newsletter!</NewsletterTitle>
                <NewsletterForm/> {/* Include the NewsletterForm component */}
                <SocialLinks>
                    <SocialIcon href="https://www.facebook.com/groups/themamahoodbayarea" target="_blank"
                                rel="noopener noreferrer"><FaFacebookF/></SocialIcon>
                    <SocialIcon href="https://www.instagram.com/the_mamahood_us" target="_blank"
                                rel="noopener noreferrer"><FaInstagram/></SocialIcon>
                    <SocialIcon href="https://www.linkedin.com/in/heather-anderson-3262086" target="_blank"
                                rel="noopener noreferrer"><FaLinkedinIn/></SocialIcon>
                </SocialLinks>
            </RightSection>
        </FooterWrapper>
    );
};

export default Footer;
