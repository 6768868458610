// src/App.js

import React, {useState} from 'react';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import styled from 'styled-components';

import LandingPage from './components/LandingPage/LandingPage';
import TheMList from './components/TheMList/TheMList';
import CommunityPage from './components/CommunityPage/CommunityPage';
import Inspiration from './components/Inspiration/Inspiration';
import TheMListOpen from './components/TheMListOpen/TheMListOpen';
import BlogPage from './components/blog/BlogPage';
import RSSFeed from './components/blog/BlogRss';
import JoinPage from './components/JoinPage/JoinPage';
import About from './components/About/About';
import Events from './components/Events/Events';
import Header from './components/Header';
import Footer from './components/Footer';
import LoginPage from './components/LoginPage';
import Profile from './components/Profile';
import PolicyPage from './components/PolicyPage';
import Settings from './components/Settings';
import Sitemap from './components/Sitemap';
import ContactPage from './components/ContactPage';
import MainPage from './components/Support/SupportPage';
import PrivacyPage from './components/PrivacyPage';
import PartnerPage from './components/PartnerPage';
import ApplyForm from './components/Application/Application';
import TheClubPage from './components/TheClub/TheClubPage';
import CookieConsentComponent from './components/CookieConsentComponent';



const AppContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    min-height: 100vh;
`;

const TheMListContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`;


const ContentWrapper = styled.div`
    flex: 1;
`;

const App = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(true);

    const login = () => setIsLoggedIn(true);
    const logout = () => setIsLoggedIn(false);

    return (
        <AppContainer>
            {isLoggedIn && <HeaderWrapper/>}
            <ContentWrapper>
                <Routes>
                    <Route path="/" element={<LandingPage/>}/>
                    <Route path="/login" element={<LoginPage login={login}/>}/>
                    <Route path="/community"
                           element={isLoggedIn ? <CommunityPage logout={logout}/> : <Navigate to="/login"/>}/>
                    <Route path="/the-mlist" element={<TheMList/>}/>
                    <Route path="/inspiration" element={<Inspiration/>}/>
                    <Route path="/join" element={<JoinPage/>}/>
                    <Route path="/about" element={<About/>}/>
                    <Route path="/events" element={<Events/>}/>
                    <Route path="/profile" element={<Profile/>}/>
                    <Route path="/settings" element={<Settings/>}/>
                    <Route path="/theclub" element={<TheClubPage/>}/>
                    <Route path="/application" element={<ApplyForm/>}/>
                    <Route path="/sitemap" element={<Sitemap/>}/>
                    <Route path="/policies" element={<PolicyPage/>}/>
                    <Route path="/privacy" element={<PrivacyPage/>}/>
                    <Route path="/contact" element={<ContactPage/>}/>
                    <Route path="/support" element={<MainPage/>}/>
                    <Route path="/partner" element={<PartnerPage/>}/>
                    <Route path="/blog" element={<BlogPage/>}/>
                    <Route path="/rss" element={<RSSFeed/>}/>
                    <Route path="/feature/:id" element={<TheMListOpen/>}/>
                </Routes>
            </ContentWrapper>
            {isLoggedIn && <FooterWrapper/>}
            <CookieConsentComponent />
        </AppContainer>
    );
};

const HeaderWrapper = () => {
    const location = useLocation();
    return location.pathname !== '/login' ? <Header currentPage={location.pathname}/> : null;
};

const FooterWrapper = () => {
    const location = useLocation();
    return location.pathname !== '/login' ? <Footer currentPage={location.pathname}/> : null;
};

export default App;
