import React from 'react';
import styled from 'styled-components';

const MissionContainer = styled.div`
    position: relative;
    width: 1280px;
  padding: 20px;
  background-color: #f8f7f6;
`;

const Paragraph = styled.p`
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
`;

const Title = styled.h1`
  font-size: 26px;
  color: #000;
  margin-bottom: 20px;
`;

const Mission = () => {
  return (
    <MissionContainer>
      <Title>The Mamahood’s Mission</Title>
      <Paragraph>
        The Mamahood’s mission is to uplift and provide holistic support to moms in every aspect of their lives, in a judgment-free environment founded in empathy, compassion, and generosity.
      </Paragraph>
      <Paragraph>
        Core to our values of fostering empathy between members, we are pro social justice. This means we are actively anti-racist, and passionately inclusive of all religions, cultures, races, ethnicities, abilities, gender identities and sexual preferences.
      </Paragraph>
      <Paragraph>
        While we ask that our members identify as ‘moms’ in some fashion, we also support their families - partners, children and parents of all gender identities and expressions.
      </Paragraph>
    </MissionContainer>
  );
};

export default Mission;
