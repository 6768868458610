// authService.js

import axios from 'axios';

const API_URL = 'https://your-django-backend/api/';

const register = (email, password) => {
    return axios.post(API_URL + 'dj-rest-auth/registration/', {
        email,
        password,
    });
};

const login = (email, password) => {
    return axios.post(API_URL + 'dj-rest-auth/login/', {
        email,
        password,
    });
};

const socialLogin = (provider, accessToken) => {
    return axios.post(API_URL + `dj-rest-auth/${provider}/`, {
        access_token: accessToken,
    });
};

export default {
    register,
    login,
    socialLogin,
};
