// src/components/ResourceSection.jsx
import React from 'react';
import styled from 'styled-components';
import resourceImage from '../../assets/Chen-Wickersham-87_babe_in_swing.png';
import mListLogo from '../../assets/Group11.png';

const SectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  width: 1280px;
        position: relative; /* This ensures that absolutely positioned children are relative to this container */

;
  background: #ffffff;
`;

const TextContainer = styled.div`
  max-width: 500px;
  text-align: left;
`;

const Heading = styled.h2`
  font-family: 'Oswald', sans-serif;
  font-size: 36px;
  color: #707070;
  margin: 0 0 20px 0;
`;

const Text = styled.p`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 20px;
  color: #82837F;
  margin: 0 0 20px 0;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 2px solid #82837F;
  color: #82837F;
  font-family: 'Oswald', sans-serif;
  font-size: 16px;
  width: 111px;
  height: 42px;
  cursor: pointer;

  &:hover {
    background: rgba(130, 131, 127, 0.1);
  }
`;

const ImageContainer = styled.div`
  position: relative;
`;

const Image = styled.img`
  width: 741px;
  height: 494px;
  object-fit: cover;
`;

const Logo = styled.img`
  position: absolute;
  top: -40px;
  left: 100px;
  width: 156px;
  height: 156px;
`;

const ResourceSection = () => {
  return (
    <SectionWrapper>
      <TextContainer>
        <Heading>Resources</Heading>
        <Text>We curate an exciting list of mama-products and services called the M-List</Text>
        <Button>M-LIST</Button>
      </TextContainer>
      <ImageContainer>
        <Image src={resourceImage} alt="Resource" />
        <Logo src={mListLogo} alt="M-List Logo" />
      </ImageContainer>
    </SectionWrapper>
  );
};

export default ResourceSection;
