import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 348px;
`;

const Row = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    gap: 12px;
`;

const Input = styled.input`
    border: 1px solid #707070;
    padding: 5px;
    height: 31px;
    width: calc(50% - 5px); /* Two inputs per row with some space between */
    font-size: 12px;

    &::placeholder {
        color: #C9C8C0;
        padding-left: 6px;
        font-size: 12px;
    }
`;

const EmailInput = styled(Input)`
    width: calc(50% - 5px); /* Email input taking 70% of the row width */
`;

const SendButton = styled.button`
    font-family: 'Oswald', sans-serif;
    font-size: 19px;
    text-transform: uppercase;
    font-weight: 500;
    height: 31px;
    width: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #53BAB4;
    color: #fff;
    padding: 5px 10px;
    border: none;
    cursor: pointer;

    &:hover {
        background-color: #2d8090;
    }
`;

const StatusMessage = styled.p`
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    color: #707070;
`;

const NewsletterForm = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
    });
    const [status, setStatus] = useState('');

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus('Sending...');

        try {
            await axios.post('https://api.convertkit.com/v3/forms/6780010/subscribe', {
                api_key: 'NprWH4Lb40viRlRmu_4Ynw',
                email: formData.email,
                first_name: formData.firstName,
                last_name: formData.lastName,
                tags: [5154365], // Add your tag IDs here
               });
            setStatus('Success! Please check your email to confirm your subscription.');
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
            });
        } catch (error) {
            console.error('Error subscribing to ConvertKit:', error);
            setStatus('An error occurred. Please try again.');
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Input
                    type="text"
                    name="firstName"
                    placeholder="FIRST NAME"
                    value={formData.firstName}
                    onChange={handleChange}
                />
                <Input
                    type="text"
                    name="lastName"
                    placeholder="LAST NAME"
                    value={formData.lastName}
                    onChange={handleChange}
                />
            </Row>
            <Row>
                <EmailInput
                    type="email"
                    name="email"
                    placeholder="EMAIL"
                    value={formData.email}
                    onChange={handleChange}
                />
                <SendButton type="submit">Send</SendButton>
            </Row>
            {status && <StatusMessage>{status}</StatusMessage>}
        </Form>
    );
};

export default NewsletterForm;