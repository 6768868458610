// src/Profile.jsx
import React, {useState} from 'react';
import styled from 'styled-components';

const ProfileContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f7f7f7;
    min-height: 100vh;
`;

const ProfileHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
`;

const Avatar = styled.img`
    border-radius: 50%;
    width: 120px;
    height: 120px;
    margin-bottom: 10px;
`;

const Name = styled.h1`
    font-family: 'Nunito Sans', sans-serif;
    font-size: 28px;
    color: #484949;
`;

const Email = styled.p`
    font-family: 'Nunito Sans', sans-serif;
    font-size: 16px;
    color: #707070;
`;

const ProfileDetails = styled.div`
    width: 100%;
    max-width: 600px;
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0px 3px 6px #00000010;
    border-radius: 10px;
    margin-bottom: 20px;
`;

const DetailItem = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;

    &:last-child {
        border-bottom: none;
    }
`;

const DetailLabel = styled.span`
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    color: #707070;
`;

const DetailValue = styled.span`
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    color: #484949;
`;

const EditButton = styled.button`
    background-color: #54bab2;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 16px;
    margin-top: 20px;

    &:hover {
        background-color: #43a5a0;
    }
`;

const EditForm = styled.form`
    width: 100%;
    max-width: 600px;
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0px 3px 6px #00000010;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
`;

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`;

const Label = styled.label`
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    color: #707070;
    margin-bottom: 5px;
`;

const Input = styled.input`
    padding: 10px;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    color: #484949;
    border: 1px solid #ddd;
    border-radius: 5px;
`;

const SaveButton = styled.button`
    background-color: #54bab2;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 16px;
    margin-top: 20px;

    &:hover {
        background-color: #43a5a0;
    }
`;


const Profile = () => {
    const user = {
        name: 'Jane Doe',
        email: 'jane.doe@example.com',
        picture: 'https://via.placeholder.com/120',
        username: 'janedoe',
        joinedDate: 'January 1, 2020',
        location: 'New York, USA'
    };
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({
        name: user.name,
        email: user.email,
        username: user.username,
        joinedDate: user.joinedDate,
        location: user.location,
    });

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission (e.g., update the user data)
        console.log('Updated Profile:', formData);
        setIsEditing(false);
    };

    return (
        <ProfileContainer>
            <ProfileHeader>
                <Avatar src={user.picture} alt={`${user.name}'s avatar`}/>
                <Name>{user.name}</Name>
                <Email>{user.email}</Email>
            </ProfileHeader>
            {isEditing ? (
                <EditForm onSubmit={handleSubmit}>
                    <FormGroup>
                        <Label htmlFor="name">Name</Label>
                        <Input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="email">Email</Label>
                        <Input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="username">Username</Label>
                        <Input
                            type="text"
                            id="username"
                            name="username"
                            value={formData.username}
                            onChange={handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="joinedDate">Joined Date</Label>
                        <Input
                            type="text"
                            id="joinedDate"
                            name="joinedDate"
                            value={formData.joinedDate}
                            onChange={handleChange}
                            disabled
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="location">Location</Label>
                        <Input
                            type="text"
                            id="location"
                            name="location"
                            value={formData.location}
                            onChange={handleChange}
                        />
                    </FormGroup>
                    <SaveButton type="submit">Save Changes</SaveButton>
                </EditForm>
            ) : (
                <>
                    <ProfileDetails>
                        <DetailItem>
                            <DetailLabel>Username:</DetailLabel>
                            <DetailValue>{user.username}</DetailValue>
                        </DetailItem>
                        <DetailItem>
                            <DetailLabel>Joined:</DetailLabel>
                            <DetailValue>{user.joinedDate}</DetailValue>
                        </DetailItem>
                        <DetailItem>
                            <DetailLabel>Location:</DetailLabel>
                            <DetailValue>{user.location}</DetailValue>
                        </DetailItem>
                        {/* Add more detail items as needed */}
                    </ProfileDetails>
                    <EditButton onClick={() => setIsEditing(true)}>Edit Profile</EditButton>
                </>
            )}
        </ProfileContainer>
    );
};
export default Profile;
