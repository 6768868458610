// src/components/CustomSection.jsx

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Section1582769775068 = styled.section`
  background-color: #000000;

  .sizer {
    padding-top: 20px;
    padding-bottom: 20px;

    @media (min-width: 768px) {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }

  .container {
    max-width: calc(1260px + 10px + 10px);
    padding-right: 10px;
    padding-left: 10px;

    @media (min-width: 768px) {
      max-width: calc(1260px + 40px + 40px);
      padding-right: 40px;
      padding-left: 40px;
    }
  }

  .container--full {
    max-width: calc(100% + 10px + 10px);

    @media (min-width: 768px) {
      max-width: calc(100% + 40px + 40px);
    }
  }
`;

const Block1582769838897 = styled.div`
  padding: 20px;
  margin: 0;
  border: 4px none black;
  border-radius: 4px;
  background-color: #000000;

  @media (min-width: 768px) {
    padding: 30px;
    margin-top: 30px;
  }

  @media (max-width: 767px) {
    text-align: center;
  }
`;

const Block1582769917521 = styled.div`
  .btn {
    border-color: #fcd77c;
    border-radius: 4px;
    color: #fcd77c;
    background-color: #fcd77c;

    &.btn-outline {
      color: #fcd77c !important;
      background: transparent;
    }
  }
`;

const Button = styled(Link)`
  display: inline-block;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  margin-top: 1rem;
  color: #fcd77c;
  border: 1px solid #fcd77c;
  border-radius: 4px;
  background-color: transparent;

  &:hover {
    background-color: #fcd77c;
    color: #000;
  }
`;

const BlockText = styled.div`
  text-align: center;
  padding: 20px;
  border: 4px none black;
  border-radius: 4px;

  h2 {
    font-family: 'Dancing Script', cursive;
    color: #fff8e8;
  }

  p {
    color: #fff8e8;
  }
`;

const FeatureImage = styled.img`
  width: 300px;
  border-radius: 4px;
`;

const Block1582797084765 = styled.div`
  margin-top: 0;
  padding: 0;
  border: 4px none black;
  border-radius: 4px;

  .image {
    width: 100%;
    max-width: 200px;
    margin: auto;
    border-radius: 4px;
  }

  .image__image {
    width: 100%;
    border-radius: 4px;
  }

  .image__overlay {
    opacity: 0;
    background-color: #fff;
    border-radius: 4px;
  }

  &:hover .image__overlay {
    opacity: 1;
  }
`;
const TheMListContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`;
const CustomSection = () => {
  return (
    <TheMListContainer>
      <Section1582769775068>
        <div className="sizer">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <Block1582769838897>
                <BlockText>
                  <h2>Set up your Facebook Group for Success.</h2>
                  <p>If you've got or want to use a Facebook group as a tool for business growth, here are 5 tips to get you off to a great start.</p>
                </BlockText>
              </Block1582769838897>

              <Block1582769917521>
                <Button to="/#two-step" data-target="#two-step" data-toggle="modal">
                  DOWNLOAD NOW
                </Button>
              </Block1582769917521>
            </div>
          </div>
        </div>
      </Section1582769775068>

      <Section1582769775068>
        <div className="sizer">
          <div className="container container--full">
            <div className="row align-items-center justify-content-center">
              <Block1582769838897>
                <BlockText>
                  <h2>COMMUNITY</h2>
                  <FeatureImage src="/static/img/theclub/VzoISwKlRU2Tr45Bi09b_123_break_300x300.png" alt="Placeholder Image" />
                  <p>Connect, collaborate, cross-promote, strategize and problem-solve with our friendly, inclusive, community of women who believe that entrepreneurialism is a team sport.</p>
                </BlockText>
              </Block1582769838897>

              <Block1582769917521>
                <BlockText>
                  <h2>INSPIRATION</h2>
                  <FeatureImage src="/static/img/theclub/5KRli9uTQc2YezWUi7bv_Inspiration.png" alt="Placeholder Image" />
                  <p>Gain ideas and insight around your next moves through inspiring content like our behind-the-scenes Voyeurpreneur series, and bite-sized ways to move the needle through our weekly Tiny Actions prompts.</p>
                </BlockText>
              </Block1582769917521>

              <Block1582769917521>
                <BlockText>
                  <h2>RESOURCES</h2>
                  <FeatureImage src="/static/img/theclub/eMrkngkVRkadAVky5ieR_2.png" alt="Placeholder Image" />
                  <p>Partake in two live masterclasses per month on topics ranging from personal development to business strategy to tech tools and gain access to all of the recorded content in The Toolbox - so you never miss a thing!</p>
                </BlockText>
              </Block1582769917521>
            </div>
          </div>
        </div>
      </Section1582769775068>

      <Section1582769775068>
        <div className="sizer">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <Block1582769917521>
                <BlockText>
                  <h2>Testimonials</h2>
                  <Block1582797084765>
                    <img className="image__image" src="/static/img/theclub/e5HV9kWTXeFPQ8CRHgRC_quotations.png" alt="Placeholder Image" />
                  </Block1582797084765>
                </BlockText>
              </Block1582769917521>
            </div>
          </div>
        </div>
      </Section1582769775068>
    </TheMListContainer>
  );
};

export default CustomSection;
