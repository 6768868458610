import React from 'react';
import FirstSection from "./FirstSection";
import CustomSection from "./CustomSection"
import CustomBlock from "./CustomBlock";
import LastSection from "./LastSection";
import styled from "styled-components";


const TheMListContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`;
const TheClubPage = () => {
    return (
        <TheMListContainer>
            <FirstSection/>
            <CustomSection/>
            <CustomBlock/>
            <LastSection/>
        </TheMListContainer>
    );
};

export default TheClubPage;
