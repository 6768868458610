// src/components/AboutSection.jsx
import React from 'react';
import styled from 'styled-components';
import backgroundImage from '../../assets/founders.png';


const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
  width: 1025px;
  background: #ffffff;
`;

const TextContainer = styled.div`
  max-width: 50%;
`;

const Heading = styled.h3`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 35px;
  color: #82837F;
  margin: 0 0 20px 0;
`;

const Paragraph = styled.p`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 18px;
  color: #707070;
  margin: 0 0 20px 0;
  line-height: 1.5;
`;

const Button = styled.button`
  background: transparent;
  border: 2px solid #82837F;
  color: #82837F;
  font-family: 'Oswald', sans-serif;
  font-size: 16px;
  width: 114px;
  height: 42px;
  cursor: pointer;

  &:hover {
    background: rgba(130, 131, 127, 0.1);
  }
`;

const Image = styled.img`
  width: 565px;
  height: 422px;
  object-fit: cover;
`;

const AboutSection = () => {
  return (
    <SectionWrapper>
      <TextContainer>
        <Heading>Who runs the Mamahood?</Heading>
        <Paragraph>
          You could say Founder, Heather Anderson, has a sort of ‘thing’ for community. Circa the 80s, it was secret clubhouse meetings and under-the-porch band practices. In High School, it was 4H campouts and rallying with the Oakland Strokes crew for pre-dawn rowing practice. In College, it was throwing parties and hosting open mikes for the ‘Dead Poet Society’.
        </Paragraph>
        <Button>ABOUT</Button>
      </TextContainer>
      <Image src={backgroundImage} alt="Founders" />
    </SectionWrapper>
  );
};

export default AboutSection;
