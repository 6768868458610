import React from "react";
import "./style.css";
import styled from "styled-components";
import Header from "../Header";

const TheMListContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    min-height: 100vh;
`;

const Events = () => {
    return (
        <TheMListContainer>
    <div className="events-https-demo">
      <div className="div">
        <img className="component" alt="Component" src="/static/img/component-3-11.png" />
        <img className="img" alt="Component" src="/static/img/group-16-1x-png.png" />
        <img className="element-the-mamahood" alt="Element the mamahood" src="/static/img/file-2024-the-mamahood.png" />
        <img
          className="partner-with-us-the"
          alt="Partner with us the"
          src="/static/img/partner-with-us-the-club-sitemap-privacy-policies-contact-1x-png.png"
        />
        <div className="overlap">
          <img className="FIRST-NAME" alt="First NAME" src="/static/img/first-name.png" />
        </div>
        <div className="overlap-group">
          <img className="LAST-NAME" alt="Last NAME" src="/static/img/last-name.png" />
        </div>
        <div className="EMAIL-wrapper">
          <img className="EMAIL" alt="Email" src="/static/img/email.png" />
        </div>
        <img className="sign-up-for-our" alt="Sign up for our" src="/static/img/sign-up-for-our-newsletter.png" />
        <div className="SEND-wrapper">
          <img className="SEND" alt="Send" src="/static/img/send.png" />
        </div>
        <img
          className="about-join-community"
          alt="About join community"
          src="/static/img/about-join-community-inspiration-resources-the-m-list-1x-png.png"
        />
        <img className="mamahood-events" alt="Mamahood events" src="/static/img/mamahood-events.png" />
        <div className="overlap-group-2">
          <img className="FILTERS" alt="Filters" src="/static/img/filters.png" />
          <img className="screenshot" alt="Screenshot" src="/static/img/screenshot-2024-06-22-at-2-33-23-pm.png" />
          <img className="screenshot-2" alt="Screenshot" src="/static/img/screenshot-2024-06-22-at-2-34-21-pm.png" />
        </div>
        <img className="group" alt="Group" src="/static/img/group-28.png" />
        <img className="screenshot-3" alt="Screenshot" src="/static/img/screenshot-2024-06-22-at-2-34-34-pm.png" />
      </div>
    </div>
        </TheMListContainer>
    );
};
export default Events;
