// src/components/UserMenu.jsx
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';

const UserMenuWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Avatar = styled.img`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 120px;
  z-index: 10;
`;

const DropdownItem = styled(Link)`
  position: relative;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  line-height: 16px;
  font-weight: ${({ active }) => (active ? '700' : '500')};
  color: #707070;
  text-decoration: none;
  text-transform: uppercase;
  padding: 10px;
  display: block;

  &:before {
    content: '';
    position: absolute;
    left: 5px;
    bottom: 0;
    width: calc(100% - 20px); /* Subtract 10px from 100% width */
    top: 17px;
    height: 10px;
    background: rgba(246, 155, 66, 0.62);
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: -1;
  }

  &:hover:before {
    opacity: 1;
  }
`;

const DropDownItemContainer = styled.div`
  display: flex;
  position: relative;
`;

const LogoutButton = styled.button`
  position: relative;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  line-height: 16px;
  font-weight: ${({ active }) => (active ? '700' : '500')};
  color: #707070;
  text-decoration: none;
  text-transform: uppercase;
  padding: 10px;
  display: block;

  &:before {
    content: '';
    position: absolute;
    left: 5px;
    bottom: 0;
    width: calc(100% - 20px); /* Subtract 10px from 100% width */
    top: 17px;
    height: 10px;
    background: rgba(246, 155, 66, 0.62);
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: -1;
  }

  &:hover:before {
    opacity: 1;
  }
`;

const UserMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user, logout } = useAuth0();
  const menuRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleItemClick = () => {
    setIsOpen(false);
  };

  return (
    <UserMenuWrapper ref={menuRef}>
      {user && <Avatar src={user.picture} alt={user.name} onClick={toggleDropdown} />}
      {isOpen && (
        <DropdownMenu>
          <DropDownItemContainer>
            <DropdownItem to="/profile" onClick={handleItemClick}>Profile</DropdownItem>
          </DropDownItemContainer>
          <DropDownItemContainer>
            <DropdownItem to="/settings" onClick={handleItemClick}>Settings</DropdownItem>
          </DropDownItemContainer>
          <DropDownItemContainer>
            <LogoutButton onClick={() => { logout({ returnTo: window.location.origin }); handleItemClick(); }}>
              Logout
            </LogoutButton>
          </DropDownItemContainer>
        </DropdownMenu>
      )}
    </UserMenuWrapper>
  );
};

export default UserMenu;
