import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const SitemapWrapper = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const SitemapHeader = styled.header`
  text-align: center;
  margin-bottom: 20px;
`;

const SitemapTitle = styled.h1`
  color: #ff6f61;
  font-size: 2.5em;
`;

const SitemapLinks = styled.ul`
  list-style: none;
  padding: 0;
`;

const SitemapLinkItem = styled.li`
  margin: 10px 0;
`;

const SitemapLink = styled(Link)`
  text-decoration: none;
  color: #ff6f61;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;

const Sitemap = () => {
  return (
    <SitemapWrapper>
      <SitemapHeader>
        <SitemapTitle>The Mamahood Sitemap</SitemapTitle>
      </SitemapHeader>
      <SitemapLinks>
        <SitemapLinkItem><SitemapLink to="/home">Home</SitemapLink></SitemapLinkItem>
        <SitemapLinkItem><SitemapLink to="/about">About Us</SitemapLink></SitemapLinkItem>
        <SitemapLinkItem><SitemapLink to="/blog">Blog</SitemapLink></SitemapLinkItem>
        <SitemapLinkItem><SitemapLink to="/community">Community</SitemapLink></SitemapLinkItem>
        <SitemapLinkItem><SitemapLink to="/resources">Resources</SitemapLink></SitemapLinkItem>
        <SitemapLinkItem><SitemapLink to="/contact">Contact Us</SitemapLink></SitemapLinkItem>
        <SitemapLinkItem><SitemapLink to="/privacy">Privacy Policy</SitemapLink></SitemapLinkItem>
        <SitemapLinkItem><SitemapLink to="/terms">Terms of Service</SitemapLink></SitemapLinkItem>
      </SitemapLinks>
    </SitemapWrapper>
  );
};

export default Sitemap;
