// src/components/WhosHereSection.jsx
import React from 'react';
import styled from 'styled-components';
import { InstagramEmbed } from 'react-social-media-embed';

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  width: 100%;
  background: #f7f7f7; /* Assuming a light background color */
`;

const Heading = styled.h1`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 45px;
  color: #484949;
  text-align: center;
  letter-spacing: 0px;
  line-height: 61px;
  margin: 0 0 20px 0;
`;

const CarouselWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  margin-top: 20px;
`;

const CarouselItem = styled.div`
  flex: 0 0 auto;
  width: 300px; /* Adjust size as needed */
  height: 300px; /* Adjust size as needed */
  margin: 0 10px;
  background: #ccc; /* Placeholder background */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Arrow = styled.div`
  font-size: 24px;
  cursor: pointer;
  user-select: none;
`;

const WhosHereSection = () => {
  return (
    <SectionWrapper>
      <Heading>Who else is here?</Heading>
      <CarouselWrapper>
        <Arrow>{"<"}</Arrow>
        <CarouselItem>
          <InstagramEmbed
            url='https://www.instagram.com/p/CG0UU3ql-5J/'
            width={320}
            height={400}
            linkText='View post on Instagram'
          />
        </CarouselItem>
        <CarouselItem>
          <InstagramEmbed
            url='https://www.instagram.com/p/CG0UU3ql-5J/'
            width={320}
            height={400}
            linkText='View post on Instagram'
          />
        </CarouselItem>
        <CarouselItem>
          <InstagramEmbed
            url='https://www.instagram.com/p/ClnPH7yrPfx/'
            width={320}
            height={400}
            linkText='View post on Instagram'
          />
        </CarouselItem>
        <Arrow>{">"}</Arrow>
      </CarouselWrapper>
    </SectionWrapper>
  );
};

export default WhosHereSection;
