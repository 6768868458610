import React from 'react';
import styled from 'styled-components';

const CommunityContainer = styled.div`
    position: relative;
    width: 1280px;
  padding: 20px;
  background-color: #f8f7f6;
`;

const Paragraph = styled.p`
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
`;

const Title = styled.h2`
  font-size: 20px;
  color: #000;
  margin-bottom: 20px;
`;

const Link = styled.a`
  color: #1155cc;
  text-decoration: underline;
`;

const CommunityConnection = () => {
  return (
    <CommunityContainer>
      <Title>COMMUNITY CONNECTION</Title>
      <Paragraph>We provide main conversational forums by region:</Paragraph>
      <Paragraph>The Mamahood - Global</Paragraph>
      <Paragraph>The Mamahood - SF Bay Area</Paragraph>
      <Paragraph>The Mamahood - Sacramento Valley (new!)</Paragraph>
      <Paragraph>The Mamahood - LA County (new!)</Paragraph>
      <Paragraph>We also provide <Link href="https://www.themamahood.co/groups/topic-specific">40+ Breakout Forums</Link> by specialty topic and need, moderated by experts where relevant.</Paragraph>
    </CommunityContainer>
  );
};

export default CommunityConnection;
