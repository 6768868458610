import React from "react";
import "./style.css";
import Header from "../Header";
import styled from "styled-components";


const TheMListContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    min-height: 100vh;
`;
export const TheMList = () => {
    return (
    <TheMListContainer>
        <div className="the-m-list">
            <div className="div">
                <img className="group" alt="Group" src="/static/img/group-20.png"/>
                <div className="overlap">
                    <div className="overlap-group">
                        <img className="welcome-to-the-m" alt="Welcome to the m"
                             src="/static/img/welcome-to-the-m-list.png"/>
                        <img className="img" alt="Group" src="/static/img/group-17.png"/>
                    </div>
                    <img className="blurb-about-the-list" alt="Blurb about the list"
                         src="/static/img/blurb-about-the-list.png"/>
                </div>
                <img className="group-2" alt="Group" src="/static/img/group-28.png"/>
                <div className="overlap-2">
                    <img className="screenshot" alt="Screenshot"
                         src="/static/img/screenshot-2024-06-06-at-11-03-23-am-1.png"/>
                    <img className="img-2" alt="Rectangle" src="/static/img/rectangle-87.png"/>
                    <img className="LITTLE-SUNBEAMS" alt="Little SUNBEAMS" src="/static/img/little-sunbeams.png"/>
                    <img className="after-school-and" alt="After school and"
                         src="/static/img/after-school-and-holiday-programs-1.png"/>
                    <img className="MARIN" alt="Marin" src="/static/img/marin-1.png"/>
                </div>
                <div className="overlap-3">
                    <img className="screenshot" alt="Screenshot"
                         src="/static/img/screenshot-2024-06-06-at-11-06-24-am-1.png"/>
                    <img className="img-2" alt="Rectangle" src="/static/img/rectangle-87.png"/>
                    <img className="DEATH-BY-CHOCOLATE" alt="Death BY CHOCOLATE"
                         src="/static/img/death-by-chocolate.png"/>
                    <img
                        className="vegan-delights-for"
                        alt="Vegan delights for"
                        src="/static/img/vegan-delights-for-special-order-1.png"
                    />
                    <img className="SAN-FRANCISCO" alt="San FRANCISCO" src="/static/img/san-francisco-1.png"/>
                </div>
                <div className="overlap-4">
                    <img className="screenshot" alt="Screenshot"
                         src="/static/img/screenshot-2024-06-06-at-11-04-03-am-1.png"/>
                    <img className="rectangle" alt="Rectangle" src="/static/img/rectangle-87.png"/>
                    <img className="CONFIDENT-DOLPHINS" alt="Confident DOLPHINS"
                         src="/static/img/confident-dolphins.png"/>
                    <img className="private-swimming" alt="Private swimming"
                         src="/static/img/private-swimming-lessons-1.png"/>
                    <img className="BERKELEY" alt="Berkeley" src="/static/img/berkeley-1.png"/>
                </div>
                <div className="overlap-5">
                    <img className="screenshot" alt="Screenshot"
                         src="/static/img/screenshot-2024-06-06-at-11-00-33-am-1.png"/>
                    <img className="img-2" alt="Rectangle" src="/static/img/rectangle-87.png"/>
                    <img className="MAYA-DOULA" alt="Maya DOULA" src="/static/img/maya-doula.png"/>
                    <img className="pre-and-post-natal" alt="Pre and post natal"
                         src="/static/img/pre-and-post-natal-support.png"/>
                    <img className="SONOMA" alt="Sonoma" src="/static/img/sonoma.png"/>
                </div>
                <img className="featured" alt="Featured" src="/static/img/featured.png"/>
                <div className="overlap-6">
                    <img className="rectangle-2" alt="Rectangle" src="/static/img/rectangle-71.png"/>
                    <img className="screenshot" alt="Screenshot"
                         src="/static/img/screenshot-2024-06-06-at-11-04-03-am-1.png"/>
                    <img className="rectangle" alt="Rectangle" src="/static/img/rectangle-87.png"/>
                    <img className="CONFIDENT-DOLPHINS" alt="Confident DOLPHINS"
                         src="/static/img/confident-dolphins-1.png"/>
                    <img className="private-swimming" alt="Private swimming"
                         src="/static/img/private-swimming-lessons-1.png"/>
                    <img className="BERKELEY" alt="Berkeley" src="/static/img/berkeley-1.png"/>
                </div>
                <img className="coaching" alt="Coaching" src="/static/img/coaching.png"/>
                <div className="overlap-7">
                    <img className="FILTERS" alt="Filters" src="/static/img/filters.png"/>
                    <img
                        className="APPAREL-SHOES-ART"
                        alt="Apparel SHOES ART"
                        src="/static/img/apparel-shoes-art-design-beauty-spa-childcare-education-fo.png"
                    />
                    <img
                        className="POC-owned-mama-owned"
                        alt="Poc owned mama owned"
                        src="/static/img/poc-owned-mama-owned-lgbtq-owned-women-led.png"
                    />
                    <img className="rectangle-3" alt="Rectangle" src="/static/img/rectangle-75.png"/>
                    <img className="rectangle-4" alt="Rectangle" src="/static/img/rectangle-75.png"/>
                    <img className="rectangle-5" alt="Rectangle" src="/static/img/rectangle-75.png"/>
                    <img className="rectangle-6" alt="Rectangle" src="/static/img/rectangle-75.png"/>
                </div>
                <img className="rectangle-7" alt="Rectangle" src="/static/img/rectangle-71.png"/>
                <img className="rectangle-8" alt="Rectangle" src="/static/img/rectangle-71.png"/>
                <img className="rectangle-9" alt="Rectangle" src="/static/img/rectangle-71.png"/>
                <img className="rectangle-10" alt="Rectangle" src="/static/img/rectangle-71.png"/>
                <img className="pregnancy-childbirth" alt="Pregnancy childbirth"
                     src="/static/img/pregnancy-childbirth.png"/>
                <img className="rectangle-11" alt="Rectangle" src="/static/img/rectangle-71.png"/>
                <img className="rectangle-12" alt="Rectangle" src="/static/img/rectangle-71.png"/>
                <img className="rectangle-13" alt="Rectangle" src="/static/img/rectangle-71.png"/>
                <img className="rectangle-14" alt="Rectangle" src="/static/img/rectangle-71.png"/>
                <img className="rectangle-15" alt="Rectangle" src="/static/img/rectangle-71.png"/>
                <img className="rectangle-16" alt="Rectangle" src="/static/img/rectangle-71.png"/>
                <img className="menopause" alt="Menopause" src="/static/img/menopause.png"/>
                <img className="rectangle-17" alt="Rectangle" src="/static/img/rectangle-71.png"/>
                <img className="rectangle-18" alt="Rectangle" src="/static/img/rectangle-71.png"/>
                <img className="rectangle-19" alt="Rectangle" src="/static/img/rectangle-71.png"/>
                <img className="rectangle-20" alt="Rectangle" src="/static/img/rectangle-71.png"/>
                <img className="rectangle-21" alt="Rectangle" src="/static/img/rectangle-71.png"/>
                <img className="rectangle-22" alt="Rectangle" src="/static/img/rectangle-71.png"/>
                <img className="rectangle-23" alt="Rectangle" src="/static/img/rectangle-71.png"/>
                <img className="rectangle-24" alt="Rectangle" src="/static/img/rectangle-71.png"/>
                <img className="EDUCATION" alt="Education" src="/static/img/education.png"/>
                <img className="WHITE-BUTTON" alt="White BUTTON" src="/static/img/white-button-1x-png.png"/>
                <img className="mamas-recommend-a" alt="Mamas recommend a"
                     src="/static/img/mamas-recommend-a-resource.png"/>
                <div className="overlap-8">
                    <img className="screenshot" alt="Screenshot"
                         src="/static/img/screenshot-2024-06-06-at-11-03-23-am-1.png"/>
                    <img className="img-2" alt="Rectangle" src="/static/img/rectangle-87.png"/>
                    <img className="LITTLE-SUNBEAMS" alt="Little SUNBEAMS" src="/static/img/little-sunbeams-1.png"/>
                    <img className="after-school-and" alt="After school and"
                         src="/static/img/after-school-and-holiday-programs-1.png"/>
                    <img className="MARIN" alt="Marin" src="/static/img/marin-1.png"/>
                </div>
                <div className="overlap-group-2">
                    <img className="screenshot" alt="Screenshot"
                         src="/static/img/screenshot-2024-06-06-at-11-06-24-am-1.png"/>
                    <img className="img-2" alt="Rectangle" src="/static/img/rectangle-87.png"/>
                    <img className="DEATH-BY-CHOCOLATE" alt="Death BY CHOCOLATE"
                         src="/static/img/death-by-chocolate-1.png"/>
                    <img
                        className="vegan-delights-for"
                        alt="Vegan delights for"
                        src="/static/img/vegan-delights-for-special-order-1.png"
                    />
                    <img className="SAN-FRANCISCO" alt="San FRANCISCO" src="/static/img/san-francisco-1.png"/>
                </div>
                <div className="overlap-9">
                    <img className="screenshot" alt="Screenshot"
                         src="/static/img/screenshot-2024-06-06-at-11-00-33-am-1.png"/>
                    <img className="img-2" alt="Component" src="/static/img/component-7-3.png"/>
                </div>
                <img className="are-you-a-business" alt="Are you a business"
                     src="/static/img/are-you-a-business-get-m-listed.png"/>
                <div className="CONTACT-wrapper">
                    <img className="CONTACT" alt="Contact" src="/static/img/contact.png"/>
                </div>
                <img className="rectangle-25" alt="Rectangle" src="/static/img/rectangle-93.png"/>
                <div className="overlap-10">
                    <img className="rectangle-26" alt="Rectangle" src="/static/img/rectangle-94.png"/>
                    <img className="SEND" alt="Send" src="/static/img/send-1x-png.png"/>
                </div>
            </div>
        </div>
    </TheMListContainer>
)

};

export default TheMList;
