import React, {useState} from 'react';
import DonateModal from './DonateModal'; // Make sure to import the Modal component
import Mission from './MissionComponent';
import Values from './ValuesComponent';
import CommunityConnection from './CommunityConnectionComponent';
import Events from './EventsComponent';
import RecommendedResources from './RecommendedResourcesComponent';
import Support from './SupportComponent';
import FinancialSupport from './FinancialSupportComponent';
import Partners from './PartnersComponent';
import styled from "styled-components";

const SupportContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 1280px;
`;

const ButtonContainer = styled.div`
    position: relative;
    display: flex;
    top: 20px;
    justify-content: center;
    width: 1280px;
    height: 100px;
`;

const SectionContainer = styled.div`
    position: relative;
    display: flex;
    width: 1280px;
    margin-bottom: 20px; // Add margin between sections if needed
`;

const BookAFacialNowButton = styled.button`
    position: relative;

    background-image: url(/static/img/rectangle-90.png);
    background-position: 50% 50%;
    background-size: cover;
    height: 33px;
    width: 152px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ImageButton = styled.img`
    height: 26px;
    width: 127px;
    object-fit: cover;
`;

const Button = styled.button`
    
    width: 200px;
    height: 50px;
    padding: 10px;
    background-color: #54bab2;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;

    &:hover {
        background-color: #43a5a0;
    }
`;

const MainPage = () => {
    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };
    return (
        <SupportContainer>
            <ButtonContainer>
                <Button onClick={openModal}>Donate Now</Button>
            </ButtonContainer>
            <SectionContainer><Mission/></SectionContainer>
            <SectionContainer><Values/> </SectionContainer>
            <SectionContainer><CommunityConnection/> </SectionContainer>
            <SectionContainer><Events/> </SectionContainer>
            <SectionContainer><RecommendedResources/> </SectionContainer>
            <SectionContainer><Support/> </SectionContainer>
            <SectionContainer><FinancialSupport/> </SectionContainer>
            <SectionContainer><Partners/> </SectionContainer>
            <ButtonContainer>
                <Button onClick={openModal}>Donate Now</Button>

            </ButtonContainer>
            <DonateModal showModal={showModal} closeModal={closeModal}/>
        </SupportContainer>
    );
};

export default MainPage;
