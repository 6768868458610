// src/components/PartnerWithUs.jsx

import React from 'react';
import styled from 'styled-components';

const PartnerContainer = styled.div`
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto;
  font-family: 'Nunito Sans', sans-serif;
  color: #333;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #f69b42;
  text-align: center;
  margin-bottom: 20px;
`;

const Section = styled.div`
  margin-top: 30px;
`;

const SectionTitle = styled.h2`
  font-size: 1.75rem;
  color: #33475b;
  margin-bottom: 15px;
`;

const SectionContent = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 20px;
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Input = styled.input`
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
`;

const TextArea = styled.textarea`
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
  height: 150px;
`;

const SubmitButton = styled.button`
  background-color: #f69b42;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-self: flex-start;

  &:hover {
    background-color: #e08936;
  }
`;

const PartnerPage = () => {
  return (
    <PartnerContainer>
      <Title>Partner With Us</Title>

      <Section>
        <SectionTitle>Why Partner With The Mamahood?</SectionTitle>
        <SectionContent>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam scelerisque aliquam odio nec venenatis.
          Sed auctor neque eu tellus rhoncus ut eleifend nibh porttitor. Ut in nulla enim. Phasellus molestie
          magna non est bibendum non venenatis nisl tempor. Suspendisse dictum feugiat nisl ut dapibus.
        </SectionContent>
        <SectionContent>
          Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.
          Donec ullamcorper nulla non metus auctor fringilla. Vestibulum id ligula porta felis euismod semper.
        </SectionContent>
      </Section>

      <Section>
        <SectionTitle>Our Partnership Opportunities</SectionTitle>
        <SectionContent>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam scelerisque aliquam odio nec venenatis.
          Sed auctor neque eu tellus rhoncus ut eleifend nibh porttitor. Ut in nulla enim. Phasellus molestie
          magna non est bibendum non venenatis nisl tempor. Suspendisse dictum feugiat nisl ut dapibus.
        </SectionContent>
      </Section>

      <Section>
        <SectionTitle>Contact Us</SectionTitle>
        <ContactForm>
          <Input type="text" placeholder="Your Name" required />
          <Input type="email" placeholder="Your Email" required />
          <TextArea placeholder="Your Message" required></TextArea>
          <SubmitButton type="submit">Send Message</SubmitButton>
        </ContactForm>
      </Section>
    </PartnerContainer>
  );
};

export default PartnerPage;
