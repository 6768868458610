// src/components/NewSection.jsx
import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';


const SectionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 1280px;
    height: 436px;
    opacity: 1;
    position: relative; /* This ensures that absolutely positioned children are relative to this container */
`;

const Rectangle = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    padding: 20px;
    color: #ffffff;

    &:first-child {
        background-color: #54bab2; /* Replace with the hex value from the design */
    }

    &:last-child {
        background-color: #e85f45; /* Replace with the hex value from the design */
    }
`;

const Heading = styled.h2`
    position: relative;
    left: 77px;
    font-family: 'Oswald', sans-serif;
    font-size: 36px;
    margin: 77 77 77px 0;
`;

const Text = styled.p`
    position: relative;
    font-family: 'Nunito Sans', sans-serif;
    left: 77px;
    font-size: 35px;
    text-align: left;
    margin: 77 77 77px 0;
`;

const Button = styled(Link)`
    position: relative;
    left: 77px;
    background: transparent;
    border: 2px solid #ffffff;
    color: #ffffff;
    font-family: 'Oswald', sans-serif;
    font-size: 16px;
    padding: 10px 20px;
    cursor: pointer;

    &:hover {
        background: rgba(255, 255, 255, 0.1);
        transform: scale(1.1);
    }
`;

// Colors
const borderColor = '#82837F';
const borderHoverColor = 'rgba(246,155,66,0.62)';
const textColor = '#82837F';

// Style the button with the winking animation
const WinkingButton = styled(Link)`
    position: absolute;
    top: 389px;
    left: 142px;
    width: 91px;
    height: 42px;
    border: 2px solid ${borderColor};
    border-radius: 0px;
    cursor: pointer;
    font-family: 'Oswald', sans-serif;
    font-size: 24px;
    color: ${textColor};
    text-align: center;
    line-height: 36px; /* Adjust line height to vertically center the text */
    transition: transform 0.2s, background-color 0.2s;

    &:hover {
        transform: scale(1.1);
        border: 2px solid ${borderHoverColor};
    }

`;

const NewSection = () => {
    return (
        <SectionWrapper>
            <Rectangle>
                <Heading>Virtual Community</Heading>
                <Text>
                    From diapers to teen drama. We’ve got 20+ topic-specific chat groups waiting for you.
                </Text>
                <Button to={"/community"}>Visit Community</Button>
            </Rectangle>
            <Rectangle>
                <Heading>In Person Events</Heading>
                <Text>
                    Check our calendar to find out where mamas are meeting up.
                </Text>

                <Button to={"/events"}>View Events</Button>
            </Rectangle>
        </SectionWrapper>
    );
};

export default NewSection;
