// src/pages/LoginPage.jsx
import React, {useState} from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import authService from "../services/authService";
import {GoogleLogin} from 'react-google-login';

// Styled components for the Login Page
const PageWrapper = styled.div`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 1280px;
    background-color: #ffffff;
`;

const Logo = styled.img`
    width: 200px;
    margin-bottom: 20px;
`;

const Title = styled.h1`
    font-family: 'Nunito Sans', sans-serif;
    font-size: 36px;
    color: #484949;
    margin-bottom: 20px;
`;

const Button = styled.button`
    width: 80%;
    padding: 10px;
    font-size: 18px;
    color: #fff;
    background-color: #54bab2;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #48a298;
    }
`;

const HyperText = styled(Link)`
    font-family: 'Nunito Sans', sans-serif;
    margin-top: 5px;
    font-size: 14px;
    font-weight: 700;
    color: #54bab2;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

const NonHyperText = styled.a`
    font-family: 'Nunito Sans', sans-serif;
    margin-top: 5px;
    font-size: 14px;
    font-weight: 400;
        color: #707070;

    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

const Divider = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 400px;
    margin: 20px 0;
    text-align: center;

    &::before,
    &::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid #ddd;
    }

    &:not(:empty)::before {
        margin-right: 0.25em;
    }

    &:not(:empty)::after {
        margin-left: 0.25em;
    }
`;

const SocialLoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 80%;
    gap: 20px;
`;

const HyperContainer = styled.div`
    display: flex;

    justify-content: left;
    width: 80%;
    gap: 5px;
`;

const LoginContainer = styled.div`
    display: flex;
    gap: 20px;
    position: relative;
    flex-direction: column;

    align-items: center;
    top: 200px;
    width: 400px;
    height: 900px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: white; /* Optional: Add a background color for better visibility */
`;

const SocialButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000010;
    cursor: pointer;
    border: none;

    &:hover {
        background-color: #f0f0f0;
    }

    svg {
        width: 24px;
        height: 24px;
    }
`;


const Input = styled.input`
    position: relative;
    border: 1px solid #707070;
    padding: 5px;
    height: 45px;
    width: 80%; /* Two inputs per row with some space between */
    font-size: 12px;

    &::placeholder {
        color: #C9C8C0;
        padding-left: 6px;
        font-size: 12px;
    }
`;

const LoginPage = () => {
    const EmailInput = styled(Input)`
        width: 80%; /* Email input taking 70% of the row width */
    `;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
    });
    const handleLogin = async () => {
        try {
            const response = await authService.login(email, password);
            console.log(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const responseGoogle = async (response) => {
        try {
            const res = await authService.socialLogin('google', response.accessToken);
            console.log(res.data);
        } catch (error) {
            console.error(error);
        }
    };

    const responseFacebook = async (response) => {
        try {
            const res = await authService.socialLogin('facebook', response.accessToken);
            console.log(res.data);
        } catch (error) {
            console.error(error);
        }
    };

    const responseApple = async (response) => {
        try {
            const res = await authService.socialLogin('apple', response.authorization.id_token);
            console.log(res.data);
        } catch (error) {
            console.error(error);
        }
    };

    const loginWithRedirect = () => {
        console.log('foo');
    }
    return (
        <PageWrapper>
            <LoginContainer>
                <Logo src="/static/img/component-3-8.png" alt="The Mamahood"/>
                <Title>Welcome</Title>
                <EmailInput
                    type="email"
                    name="email"
                    placeholder="EMAIL"
                    value={formData.email}
                    onChange={handleChange}
                />
                <Input
                    type="text"
                    name="password"
                    placeholder="PASSWORD"
                    value={formData.lastName}
                    onChange={handleChange}
                />
                <HyperContainer><HyperText>Forgot password?</HyperText></HyperContainer>
                <Button onClick={() => loginWithRedirect()}>Continue</Button>
                <HyperContainer><NonHyperText>Don't have an account? </NonHyperText><HyperText>Sign Up</HyperText></HyperContainer>

                <Divider>OR</Divider>
                <SocialLoginContainer>

                    <GoogleLogin
                        clientId="laksdjflksdjflksadjflkasjdfl"
                        buttonText="Login with Google"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy={'single_host_origin'}
                    />

                    <GoogleLogin
                        clientId="laksdjflksdjflksadjflkasjdfl"
                        buttonText="Login with Google"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy={'single_host_origin'}
                    />

                    <GoogleLogin
                        clientId="laksdjflksdjflksadjflkasjdfl"
                        buttonText="Login with Google"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy={'single_host_origin'}
                    />

                </SocialLoginContainer>
            </LoginContainer>
        </PageWrapper>
    );
};

export default LoginPage;
