// src/components/CustomBlocks.jsx

import React from 'react';
import styled from 'styled-components';

const Block = styled.div`
  box-shadow: none;
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  @media (min-width: 768px) {
    padding: 30px;
  }
`;

const FeatureImage = styled.img`
  width: 350px;
  border-radius: 4px;
`;

const FeatureText = styled.div`
  h4 {
    font-weight: 400;
  }
  p {
    font-weight: 400;
  }
`;

const Button = styled.a`
  display: inline-block;
  margin-top: 1rem;
  border: 1px solid #fcd77c;
  border-radius: 4px;
  color: #000;
  background-color: #fcd77c;
  text-decoration: none;
  padding: 0.5rem 1rem;
  &:hover {
    background-color: #000;
    color: #fcd77c;
  }
`;

const Section = styled.div`
  background-color: #ebebeb;
  padding-top: 30px;
  padding-bottom: 30px;
  .container {
    max-width: calc(1260px + 10px + 10px);
    padding: 0 10px;
    @media (min-width: 768px) {
      max-width: calc(1260px + 40px + 40px);
      padding: 0 40px;
    }
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch !important;
  }
`;

const Footer = styled.footer`
  background-color: #293039;
  color: #fff;
  padding: 1rem;
  text-align: center;
  a {
    color: #fff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .social-icons__icon {
    margin: 0 0.5rem;
    color: #293039 !important;
    background-color: #fff;
    border-radius: 50%;
    padding: 0.5rem;
    display: inline-block;
  }
`;

const TheMListContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`;


const LastSection = () => {
  return (
      <TheMListContainer>
    <main>
      <Section>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <Block>
              <FeatureImage src="/static/img/cGWSdx96Rp6L3fczx0mg_Yellow_ClubhouseApp2.png" alt="Placeholder Image" />
              <FeatureText>
                <h4>THE CLUB + COMMUNITY!</h4>
                <p>So many epic buzz terms. What better word than “Community”?&nbsp;</p>
                <p>Well this app&nbsp;smartly nabbed&nbsp;the most amazing name - and the appeal is real. </p>
              </FeatureText>
              <Button href="https://the-club.mykajabi.com/blog/the-club-community">Read More</Button>
            </Block>

            <Block>
              <FeatureImage src="/static/img/4TcYrAWARLaTWgkawu1X_Blog_LightBlue_LiarLiar.png" alt="Placeholder Image" />
              <FeatureText>
                <h4>THE CLUB + TINY BOOK COURSE</h4>
                <p>
                  This past Fall, we brought a life-changing experience to The Club called Tiny Book Course by Alexandra
                  Franzen and Lindsay Smith.
                </p>
              </FeatureText>
              <Button href="https://the-club.mykajabi.com/blog/the-club-tiny-book-course">Read More</Button>
            </Block>
          </div>
        </div>
      </Section>

      <Footer>
        <div className="container">
          <div className="social-icons social-icons--round">
            <a href="https://www.facebook.com/groups/1414582888848987" className="social-icons__icon">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="https://www.instagram.com/theclub_us/" className="social-icons__icon">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://www.linkedin.com/in/heather-anderson-3262086/" className="social-icons__icon">
              <i className="fab fa-linkedin-in"></i>
            </a>
          </div>
        </div>
      </Footer>
    </main>
        </TheMListContainer>
  );
};

export default LastSection;
