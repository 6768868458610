// src/components/LandingPage/LandingPage.jsx
import React from "react";
import styled from "styled-components";
import ImageSection from "./ImageSection";
import NewSection from "./NewSection";
import InspirationSection from "../InspirationSection";
import ResourceSection from "./ResourceSection";
import JoinSection from "./JoinSection";
import WhosHereSection from "./WhosHereSection";
import AboutSection from "./AboutSection";
import MListSection from "./MListSection";
import PartnerSection from "./PartnerSection";


const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    min-height: 100vh;
`;
const LandingPage = () => {
    return (
        <SectionContainer>

            <ImageSection/>
            <NewSection/>
            <InspirationSection/>
            <ResourceSection/>
            <JoinSection/>
            <WhosHereSection/>
            <AboutSection/>
            <MListSection/>
            <PartnerSection/>
        </SectionContainer>
    );
};
export default LandingPage;
