// src/components/PrivacyPage.jsx

import React from 'react';
import styled from 'styled-components';

const PrivacyContainer = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  font-family: 'Nunito Sans', sans-serif;
  color: #333;

  h1 {
    font-size: 2.5rem;
    color: #f69b42;
    text-align: center;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 1.75rem;
    color: #33475b;
    margin-top: 30px;
    margin-bottom: 15px;
  }

  p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 20px;
  }

  ul {
    list-style-type: disc;
    margin-left: 20px;
  }

  li {
    margin-bottom: 10px;
  }
`;

const PrivacyPage = () => {
  return (
    <PrivacyContainer>
      <h1>The Mamahood Privacy Policy</h1>
      <h2>Introduction</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.
      </p>

      <h2>Information We Collect</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.
      </p>
      <ul>
        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
        <li>Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.</li>
        <li>Sed nisi. Nulla quis sem at nibh elementum imperdiet.</li>
      </ul>

      <h2>How We Use Your Information</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.
      </p>
      <ul>
        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
        <li>Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.</li>
        <li>Sed nisi. Nulla quis sem at nibh elementum imperdiet.</li>
      </ul>

      <h2>Third-Party Sharing</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.
      </p>

      <h2>Security</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.
      </p>

      <h2>Changes to This Policy</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.
      </p>

      <h2>Contact Us</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.
      </p>
    </PrivacyContainer>
  );
};

export default PrivacyPage;
