// src/components/JoinSection.jsx
import React from 'react';
import styled from 'styled-components';

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  width: 100%;
  background: #ffffff;
`;

const Heading = styled.h1`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 50px;
  color: #484949;
  text-align: center;
  letter-spacing: 0px;
  line-height: 69px;
  margin: 0 0 20px 0;
`;

const Highlight = styled.div`
  width: 91px;
  height: 42px;
  background-color: #f69b42;
  margin: 10px 0;
`;

const Text = styled.p`
  font-family: 'Caveat', sans-serif;
  font-size: 24px;
  color: #707070;
  text-align: center;
  margin: 0 0 20px 0;
`;

const JoinSection = () => {
  return (
    <SectionWrapper>
      <Heading>Nailing this mama-thing together</Heading>
      <Highlight />
      <Text>(IT'S FREE!)</Text>
    </SectionWrapper>
  );
};

export default JoinSection;
