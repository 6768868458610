import React from 'react';
import styled from 'styled-components';

const EventsContainer = styled.div`
    position: relative;
    width: 1280px;
  padding: 20px;
  background-color: #ffffff;
`;

const Paragraph = styled.p`
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
`;

const Title = styled.h2`
  font-size: 20px;
  color: #000;
  margin-bottom: 20px;
`;

const Link = styled.a`
  color: #1155cc;
  text-decoration: underline;
`;

const Events = () => {
  return (
    <EventsContainer>
      <Title>EVENTS</Title>
      <Paragraph>We host virtual and in-person mom events year round:</Paragraph>
      <Paragraph>
        <Link href="https://www.themamahood.co/the-m-list/events/">https://www.themamahood.co/the-m-list/events/</Link>
      </Paragraph>
    </EventsContainer>
  );
};

export default Events;
