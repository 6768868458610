import React from 'react';
import styled from 'styled-components';

const SupportContainer = styled.div`
    position: relative;
    width: 1280px;
  padding: 20px;
  background-color: #ffffff;
`;

const Paragraph = styled.p`
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
`;

const Title = styled.h2`
  font-size: 20px;
  color: #000;
  margin-bottom: 20px;
`;

const Support = () => {
  return (
    <SupportContainer>
      <Title>FREE Ways to Support The Mamahood</Title>
      <Paragraph>Listen:</Paragraph>
      <Paragraph>Read:</Paragraph>
      <Paragraph>Subscribe:</Paragraph>
      <Paragraph>Connect via direct text:</Paragraph>
    </SupportContainer>
  );
};

export default Support;
