// src/components/Header.jsx
import React from 'react';
import styled from 'styled-components';
import wordmark from '../assets/wordmark.png';
import { FaFacebookF, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import UserMenu from './UserMenu';

const TextLink = styled(Link)`
    position: relative;
    display: inline-block;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    line-height: 16px;
    font-weight: ${({ active }) => (active ? '700' : '500')};
    color: #707070;
    text-decoration: none;
    text-transform: uppercase;
    padding-bottom: 2px;
    z-index: 1;

    &:before {
        content: '';
        position: absolute;
        left: -5px;
        bottom: 72px;
        width: 100%;
        height: 10px;
        background: rgba(246, 155, 66, 0.62);
        opacity: 0;
        transition: opacity 0.3s ease;
        z-index: -1;
    }

    &:hover:before {
        opacity: 1;
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    align-items: flex-start;
    height: 104px;
    width: 1280px;
`;

const HeaderWrapper = styled.header`
    display: flex;
    top: 24px;
    left: 31px;
    
    width: 1206.38px;
    position: relative;
    background-color: #ffffff;
`;

const Logo = styled.div`
    display: flex;
    position: relative;
    top: 19px;
    left: 0px;

    img {
        width: 336px;
        height: 39px;
    }
`;

const NavWrapper = styled.div`
    display: flex;
    position: relative;
    height: 88.68px;
    top: 6px;
    width: 600px;
    left: 90px;
`;

const NavLinks = styled.div`
    display: flex;
    position: relative;
    top: 25px;
    gap: 36.5px;
`;

const LogoutButton = styled.button`
    display: block;
    width: 100%;
    padding: 10px;
    text-decoration: none;
    color: #333;
    font-size: 14px;
    background: none;
    border: none;
    text-align: left;
    cursor: pointer;

    &:hover {
        background-color: #f7f7f7;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    position: relative; 
    align-items: center;
    left: 140px;
    top: 24px;
    width: 65px;
    height: 28px;
`;

const LoginButton = styled.button`

    background-color: #54bab2;
    color: white;
    padding: 3.5px 15.5px;
    border: none;
    border-radius: 0px;
    cursor: pointer;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;

    &:hover {
        background-color: #43a5a0;
    }
`;

const SocialLinks = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    left: 190px;
    gap: 10px;

    a {
        color: #F69B42;
        font-size: 16px;

        &:hover {
            color: #54bab2;
        }
    }
`;

const SocialIcon = styled.a`
    color: #f69b42;
    font-size: 24px;
    text-decoration: none;

    &:hover {
        color: #54bab2;
    }
`;

const ImageLink = styled.a`
    /* Add any additional styling for the link here if needed */
`;

const Header = ({ currentPage }) => {
    const { isAuthenticated, logout, user } = useAuth();
    const navigate = useNavigate();

    console.log('isAuthenticated:', isAuthenticated);
    console.log('user:', user);

    const handleLogin = () => {
        navigate('/login');
    };

    return (
        <HeaderContainer>
            <HeaderWrapper>
                <Logo>
                    <ImageLink href="/">
                        <img src={wordmark} alt="The Mamahood" />
                    </ImageLink>
                </Logo>

                <NavWrapper>
                    <NavLinks>
                        <TextLink to="/about" active={currentPage === '/about'}>About</TextLink>
                        <TextLink to="/join" active={currentPage === '/join'}>Join</TextLink>
                        <TextLink to="/inspiration" active={currentPage === '/inspiration'}>Inspiration</TextLink>
                        <TextLink to="/community" active={currentPage === '/community'}>Community</TextLink>
                        <TextLink to="/the-mlist" active={currentPage === '/the-mlist'}>The M List</TextLink>
                        <TextLink to="/support" active={currentPage === '/support'}>Support</TextLink>
                    </NavLinks>
                </NavWrapper>
                <ButtonWrapper>
                    {isAuthenticated ? (
                        <UserMenu />
                    ) : (
                        <LoginButton onClick={handleLogin}>Login</LoginButton>
                    )}
                </ButtonWrapper>
                <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                    <SocialLinks>
                        <SocialIcon href="https://www.linkedin.com/in/heather-anderson-3262086" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></SocialIcon>
                        <SocialIcon href="https://www.instagram.com/the_mamahood_us" target="_blank" rel="noopener noreferrer"><FaInstagram /></SocialIcon>
                        <SocialIcon href="https://www.facebook.com/groups/themamahoodbayarea" target="_blank" rel="noopener noreferrer"><FaFacebookF /></SocialIcon>
                    </SocialLinks>
                </div>
                {/* Add any other header content here */}
            </HeaderWrapper>
        </HeaderContainer>
    );
};

export default Header;
