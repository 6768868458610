import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import RSSFeed from "./BlogRss";

const BlogContainer = styled.div`
    padding: 20px;
    max-width: 800px;
    margin: auto;
    background-color: #f8f7f6;
    border-radius: 8px;
`;

const BlogPost = styled.div`
    margin-bottom: 20px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const BlogTitle = styled.h2`
    font-size: 24px;
    color: #333;
`;

const BlogDate = styled.p`
    font-size: 14px;
    color: #777;
`;

const BlogSummary = styled.p`
    font-size: 16px;
    color: #555;
`;

const BlogContent = styled.div`
    font-size: 16px;
    color: #555;
    line-height: 1.6;

    p {
        margin-bottom: 15px;
    }
`;

const BlogImage = styled.img`
    max-width: 100%;
    border-radius: 8px;
    margin-bottom: 10px;
`;

const BlogLink = styled.a`
    display: inline-block;
    margin-top: 10px;
    color: #54bab2;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

const Blog = () => {
    const [posts, setPosts] = useState([
        {
            title: "First Blog Post",
            link: "https://example.com/first-post",
            published: "2024-01-01T00:00:00Z",
            summary: "This is a summary of the first blog post.",
            image: "https://example.com/image1.jpg",
            content: `
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus imperdiet, nulla et dictum interdum, nisi lorem egestas odio, vitae scelerisque enim ligula venenatis dolor. Maecenas nisl est, ultrices nec congue eget, auctor vitae massa.</p>
                <img src="https://example.com/content-image1.jpg" alt="Content Image 1"/>
                <p>Fusce luctus vestibulum augue ut aliquet. Mauris ante ligula, facilisis sed ornare eu, lobortis in odio. Praesent convallis urna a lacus interdum ut hendrerit risus congue.</p>
                <p>Ut non enim eleifend felis pretium feugiat. Vivamus quis mi. Phasellus a est. Phasellus magna. In hac habitasse platea dictumst.</p>
                <img src="https://example.com/content-image2.jpg" alt="Content Image 2"/>
                <p>Proin sapien ipsum, porta a, auctor quis, euismod ut, mi. Proin sodales libero eget ante. Nulla quam. Aenean laoreet. Vestibulum nisi lectus, commodo ac, facilisis ac, ultricies eu, pede.</p>
                <img src="https://example.com/content-image3.jpg" alt="Content Image 3"/>
            `
        },
        {
            title: "Second Blog Post",
            link: "https://example.com/second-post",
            published: "2024-02-01T00:00:00Z",
            summary: "This is a summary of the second blog post.",
            image: "https://example.com/image2.jpg",
            content: `
                <p>Sed augue ipsum, egestas nec, vestibulum et, malesuada adipiscing, dui. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu.</p>
                <img src="https://example.com/content-image4.jpg" alt="Content Image 4"/>
                <p>In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus.</p>
                <p>Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim.</p>
                <img src="https://example.com/content-image5.jpg" alt="Content Image 5"/>
                <p>Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet.</p>
                <img src="https://example.com/content-image6.jpg" alt="Content Image 6"/>
            `
        },
        {
            title: "Third Blog Post",
            link: "https://example.com/third-post",
            published: "2024-03-01T00:00:00Z",
            summary: "This is a summary of the third blog post.",
            image: "https://example.com/image3.jpg",
            content: `
                <p>Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet.</p>
                <img src="https://example.com/content-image7.jpg" alt="Content Image 7"/>
                <p>Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus.</p>
                <p>Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id, lorem.</p>
                <img src="https://example.com/content-image8.jpg" alt="Content Image 8"/>
                <p>Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi.</p>
                <img src="https://example.com/content-image9.jpg" alt="Content Image 9"/>
            `
        }
    ]);

    // Comment out useEffect to prevent fetching during this mock data phase
    /*
    useEffect(() => {
        fetch('/api/blog-posts/')
            .then(response => response.json())
            .then(data => setPosts(data))
            .catch(error => console.error('Error fetching blog posts:', error));
    }, []);
    */

    return (
        <BlogContainer>
            {posts.map((post, index) => (
                <BlogPost key={index}>
                    {post.image && <BlogImage src={post.image} alt={post.title} />}
                    <BlogTitle>{post.title}</BlogTitle>
                    <BlogDate>{new Date(post.published).toLocaleDateString()}</BlogDate>
                    <BlogSummary>{post.summary}</BlogSummary>
                    <BlogContent dangerouslySetInnerHTML={{ __html: post.content }} />
                    <BlogLink href={post.link} target="_blank" rel="noopener noreferrer">
                        Read more
                    </BlogLink>
                </BlogPost>
            ))}
        <RSSFeed/>
        </BlogContainer>
    );
};

export default Blog;
