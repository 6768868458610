// src/components/MListSection.jsx
import React from 'react';
import styled, {keyframes} from 'styled-components';
import logo from '../../assets/Group18.png';
import {Link} from 'react-router-dom';


// Pseudo-database
const mListItems = [
    {
        location: 'SACRAMENTO',
        title: 'GLOW SALON',
        subtitle: 'Special occasion hair and make-up',
        id: 1234,
        image: require('../../assets/Screenshot_2024-06-06_at_11.06.41 AM.png'),
    },
    {
        location: 'SACRAMENTO',
        title: 'SEASONS LIFE COACHING',
        subtitle: 'Coaching for women going through life changes',
        id: 1234,
        image: require('../../assets/Screenshot_2024-06-06_at_11.01.49 AM.png'),
    },
    {
        location: 'SACRAMENTO',
        title: 'GLOW SALON',
        subtitle: 'Special occasion hair and make-up',
        id: 1234,
        image: require('../../assets/Screenshot_2024-06-06_at_11.04.34 AM.png'),
    },

    // Add more items as needed
];
const ItemTextOverlay = styled(Link)`
    position: relative;
    width: 311px;
    height: 88px;
    top: -44px;
    background: #ffffff;
    box-shadow: 0px 3px 6px #00000010;
    padding: 10px;
    text-align: center;

    &:hover {
        transform: scale(1.1);
    }
`;

const SectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    height: 769px;
    width: 1280px;
`;

const Heading = styled.h1`
    font-family: 'Nunito Sans', sans-serif;
    font-size: 50px;
    color: #484949;
    text-align: center;
    letter-spacing: 0px;
    line-height: 69px;
    margin: 0 0 20px 0;
`;

const HeadingWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
`;

const ItemWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 20px;
`;

const Item = styled.div`
    position: relative;
    background: #ffffff;
    margin: 0px;
    width: 418px;
    height: 441px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
`;

const ItemImage = styled.img`
    width: 418px;
    height: auto;
`;

const ItemLocation = styled.h2`
    font-family: 'EB Garamond', serif;
    font-size: 12px;
    color: #707070;
    margin: 0px;
`;

const ItemTitle = styled.h2`
    font-family: 'Oswald', sans-serif;
    font-size: 22px;
    font-weight: 500;
    color: #e85f45;
    margin: 0px 0;
`;


const ItemSubtitle = styled.p`
    font-family: 'Nunito Sans', sans-serif;
    font-size: 12px;
    color: #82837F;
    text-align: center;
`;

const Logo = styled.img`
    position: absolute;
    left: 750px;
    top: 40px;
    transform: translateY(-50%);
    width: 156px;
    height: auto;
`;

// Define keyframes for the winking animation
const wink = keyframes`
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
`;

const MListSection = () => {
    return (
        <SectionWrapper>
            <HeadingWrapper>
                <Heading>Things we love</Heading>
                <Logo src={logo} alt="M List Logo"/>
            </HeadingWrapper>
            <ItemWrapper>
                {mListItems.map((item, index) => (
                    <Item key={index}>
                        <ItemImage src={item.image} alt={item.title}/>
                        <ItemTextOverlay to={`/feature/${item.id}`}>
                            <ItemLocation>{item.location}</ItemLocation>
                            <ItemTitle>{item.title}</ItemTitle>
                            <ItemSubtitle>{item.subtitle}</ItemSubtitle>
                        </ItemTextOverlay>
                    </Item>
                ))}
            </ItemWrapper>
        </SectionWrapper>
    );
};

export default MListSection;
