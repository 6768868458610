import React, {useState} from 'react';
import styled from 'styled-components';
import BookNowModal from './BookNowModal'; // Make sure to import the Modal component



const TheMListOpenContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 734px;
    width: 1280px;

`;

const InnerContainer = styled.div`
    position: relative;
    background-color: #F8F7F6;
    height: 734px;
    width: 1280px;
`;

const TopLeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    height: 734px;
    width: 1280px;
`;


const GroupImage = styled.img`
    height: 200px;
    left: 91px;
    object-fit: cover;
    position: absolute;
    top: 978px;
    width: 1098px;
`;

const OverlapGroup = styled.div`
    height: 734px;
    left: 0;
    position: absolute;
    top: 0px;
    width: 1280px;
`;

const Image = styled.img`
    height: ${({height}) => height || 'auto'};
    left: ${({left}) => left || '0'};
    object-fit: cover;
    position: absolute;
    top: ${({top}) => top || '0'};
    width: ${({width}) => width || 'auto'};
`;

const ImageButton = styled.img`
    height: 26px;
    width: 127px;
    object-fit: cover;
`;
const Overlap = styled.div`
    height: 46px;
    left: 225px;
    position: absolute;
    top: 624px;
    width: 22px;
`;

const BookAFacialNowWrapper = styled.div`
    background-image: url(/static/img/rectangle-90.png);
    background-position: 50% 50%;
    background-size: cover;
    height: 33px;
    left: 583px;
    position: absolute;
    top: 567px;
    width: 152px;
`;


const CarouselContainer = styled.div`
    position: relative;
    left: 235px;
    top: 146px;
    width: 365px;
    height: 300px;
    margin: auto;
`;

const CarouselImage = styled.img`
    width: 365px;
    height: auto;
    object-fit: cover;
`;

const DotsContainer = styled.div`
    position: relative;
    top: 15px;
    bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
`;

const Dot = styled.span`
    position: relative;
    height: 10px;
    width: 10px;
    margin: 0 5px;
    background-color: ${props => (props.active ? '#888' : '#bbb')};
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
    cursor: pointer;
`;

const BookAFacialNowButton = styled.button`
    background-image: url(/static/img/rectangle-90.png);
    background-position: 50% 50%;
    background-size: cover;
    height: 33px;
    width: 152px;
    border: none;
    position: absolute;
    left: 583px;
    top: 567px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`;


export const TheMListOpen = () => {

    const pseudoDatabase = [
        '/static/img/lilacPlusFlint_CarouselImage_01.png',
        '/static/img/lilacPlusFlint_CarouselImage_02.png',
        '/static/img/lilacPlusFlint_CarouselImage_03.png',
        // Add more image paths as needed
    ];

    const [currentIndex, setCurrentIndex] = useState(0);
    const [showModal, setShowModal] = useState(false);


    const nextImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % pseudoDatabase.length);
    };

    const prevImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + pseudoDatabase.length) % pseudoDatabase.length);
    };

    const goToImage = (index) => {
        setCurrentIndex(index);
    };

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <TheMListOpenContainer>

            <InnerContainer>
                <CarouselContainer>
                    <CarouselImage src={pseudoDatabase[currentIndex]} alt={`Slide ${currentIndex}`}/>
                    {pseudoDatabase.length > 1 && (
                        <DotsContainer>
                            {pseudoDatabase.map((_, index) => (
                                <Dot
                                    key={index}
                                    active={index === currentIndex}
                                    onClick={() => goToImage(index)}
                                />
                            ))}
                        </DotsContainer>
                    )}
                </CarouselContainer>
                <Image height="67px" left="223px" top="137px" width="188px" alt="Lilac FLINT"
                       src="/static/img/lilac-flint.png"/>
                <Image height="64px" left="223px" top="212px" width="351px" alt="A boutique skincare"
                       src="/static/img/a-boutique-skincare-product-line-and-skincare-studio.png"/>
                <Image height="16px" left="221px" top="495px" width="74px" alt="Women LED"
                       src="/static/img/women-led.png"/>
                <Image height="16px" left="323px" top="495px" width="90px" alt="Mama OWNED"
                       src="/static/img/mama-owned.png"/>
                <Image height="20px" left="256px" top="636px" width="41px" alt="Back"
                       src="/static/img/back.png"/>
                <Overlap>
                    <Image height="25px" top="0" width="22px" alt="Line" src="/static/img/line-10.png"/>
                    <Image height="22px" top="24px" width="22px" alt="Line" src="/static/img/line-13.png"/>
                </Overlap>
                <Image height="20px" left="981px" top="635px" width="44px" alt="More"
                       src="/static/img/more.png"/>
                <Image height="46px" left="1036px" top="621px" width="22px" alt="Group"
                       src="/static/img/group-11-1x-png.png"/>
                <Image height="99px" left="223px" top="311px" width="394px" alt="At lilac flint we"
                       src="/static/img/at-lilac-flint-we-serve-up-skincare-that-s-a-little-nerdy-a.png"/>
                <Image height="19px" left="223px" top="113px" width="121px" alt="Sacremento CA"
                       src="/static/img/sacremento-ca.png"/>
                <Image height="12px" left="981px" top="113px" width="57px" alt="Edit profile"
                       src="/static/img/edit-profile.png"/>

                <Image height="16px" left="331px" top="445px" width="74px" alt="Component"
                       src="/static/img/group-16-1.png"/>
                <BookAFacialNowButton onClick={openModal}>
                    <ImageButton src="/static/img/book-a-facial-now.png" alt="Book a facial now"/>
                </BookAFacialNowButton>
                <Image height="19px" left="222px" top="445px" width="84px" alt="Group"
                       src="/static/img/group-37.png"/>

            </InnerContainer>
            <BookNowModal showModal={showModal} closeModal={closeModal}/>
        </TheMListOpenContainer>
    );
};

export default TheMListOpen;
