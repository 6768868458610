import React, { useState } from 'react';
import styled from 'styled-components';

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  width: 800px;
  max-height: 90vh; /* Adjust this value as needed */
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  overflow-y: auto; /* Enable vertical scrolling */
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;

const StepContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const StepTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 10px;
`;

const ServiceOption = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const RadioInput = styled.input`
  margin-right: 10px;
`;

const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

const Calendar = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const CalendarDay = styled.div`
  width: 14.28%;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #ddd;
`;

const TimeSlot = styled.div`
  padding: 10px;
  cursor: pointer;
  border: 1px solid #ddd;
  margin: 5px;
`;

const FormField = styled.div`
  margin-bottom: 10px;
  width: 100%;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const PaymentMethod = styled.div`
  margin-bottom: 10px;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const pseudoDatabase = {
  services: [
    { id: 1, name: 'Swedish massage', duration: 60, price: 110 },
    { id: 2, name: 'Example service', duration: 90, price: 290 },
  ],
  availableDates: [
    '2023-07-01', '2023-07-02', '2023-07-03', '2023-07-04', '2023-07-05',
    '2023-07-06', '2023-07-07', '2023-07-08', '2023-07-09', '2023-07-10'
  ],
  availableTimes: ['10:00', '11:00', '13:00', '15:00', '16:00', '17:00'],
};

const BookingInterface = ({ showModal, closeModal }) => {
  const [selectedService, setSelectedService] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    location: '',
  });
  const [paymentMethod, setPaymentMethod] = useState('creditCard');

  if (!showModal) return null;

  const handleServiceChange = (e) => {
    setSelectedService(e.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handleSubmit = () => {
    // Submit booking information to the backend or pseudo database
    console.log({
      selectedService,
      selectedDate,
      selectedTime,
      formData,
      paymentMethod,
    });
    closeModal();
  };

  return (
    <ModalBackground>
      <ModalContainer>
        <CloseButton onClick={closeModal}>&times;</CloseButton>
        <StepContainer>
          <StepTitle>1. Choose a service</StepTitle>
          {pseudoDatabase.services.map((service) => (
            <ServiceOption key={service.id}>
              <RadioInput
                type="radio"
                name="service"
                value={service.id}
                onChange={handleServiceChange}
              />
              <div>
                {service.name} - {service.duration} min - ${service.price}
              </div>
            </ServiceOption>
          ))}
        </StepContainer>

        <StepContainer>
          <StepTitle>2. Date and time</StepTitle>
          <CalendarContainer>
            <Calendar>
              {pseudoDatabase.availableDates.map((date) => (
                <CalendarDay key={date} onClick={() => handleDateChange(date)}>
                  {date}
                </CalendarDay>
              ))}
            </Calendar>
            <div>
              {pseudoDatabase.availableTimes.map((time) => (
                <TimeSlot key={time} onClick={() => handleTimeChange(time)}>
                  {time}
                </TimeSlot>
              ))}
            </div>
          </CalendarContainer>
        </StepContainer>

        <StepContainer>
          <StepTitle>3. Add your information</StepTitle>
          <FormField>
            <Label>Full Name</Label>
            <Input
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleInputChange}
            />
          </FormField>
          <FormField>
            <Label>Email</Label>
            <Input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </FormField>
          <FormField>
            <Label>Location</Label>
            <Input
              type="text"
              name="location"
              value={formData.location}
              onChange={handleInputChange}
            />
          </FormField>
        </StepContainer>

        <StepContainer>
          <StepTitle>4. Payment</StepTitle>
          <PaymentMethod>
            <label>
              <RadioInput
                type="radio"
                name="paymentMethod"
                value="cash"
                checked={paymentMethod === 'cash'}
                onChange={handlePaymentMethodChange}
              />
              Cash
            </label>
            <label>
              <RadioInput
                type="radio"
                name="paymentMethod"
                value="creditCard"
                checked={paymentMethod === 'creditCard'}
                onChange={handlePaymentMethodChange}
              />
              Credit Card
            </label>
          </PaymentMethod>
          {paymentMethod === 'creditCard' && (
            <>
              <FormField>
                <Label>Card Number</Label>
                <Input type="text" name="cardNumber" onChange={handleInputChange} />
              </FormField>
              <FormField>
                <Label>Name on Card</Label>
                <Input type="text" name="nameOnCard" onChange={handleInputChange} />
              </FormField>
              <FormField>
                <Label>Expiration Date</Label>
                <Input type="text" name="expirationDate" onChange={handleInputChange} />
              </FormField>
              <FormField>
                <Label>CVV</Label>
                <Input type="text" name="cvv" onChange={handleInputChange} />
              </FormField>
            </>
          )}
        </StepContainer>

        <Button onClick={handleSubmit}>Book Now</Button>
        <Button onClick={handleSubmit}>Cancel</Button>
      </ModalContainer>
    </ModalBackground>
  );
};

export default BookingInterface;
