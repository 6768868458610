import React from "react";
import "./style.css";
import styled from "styled-components";
import Header from "../Header";

const TheMListContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    min-height: 100vh;
`;

const Inspiration = () => {
    return (
        <TheMListContainer>
            <div className="INSPIRATION">
                <div className="div">
                    <div className="overlap-group">
                        <img className="rectangle" alt="Rectangle" src="/static/img/rectangle-19-1x-png.png"/>
                        <img className="WOMEN-FOUNDERS" alt="Women FOUNDERS" src="/static/img/women-founders-1.png"/>
                    </div>
                    <img className="group" alt="Group" src="/static/img/group-20-1x-png.png"/>
                    <div className="overlap">
                        <img className="img" alt="Rectangle" src="/static/img/rectangle-2.png"/>
                        <img className="element" alt="Element"
                             src="/static/img/8b-2734947-6bc-4a-4432-9a-937-4c-073-4c-66.png"/>
                    </div>
                    <img className="leilani-lopez" alt="Leilani lopez" src="/static/img/leilani-lopez.png"/>
                    <img className="WOMEN-FOUNDERS-2" alt="Women FOUNDERS" src="/static/img/women-founders.png"/>
                    <img
                        className="championing"
                        alt="Championing"
                        src="/static/img/championing-specialized-insurance-solutions-for-california-busi.png"
                    />
                    <img
                        className="written-by-heather"
                        alt="Written by heather"
                        src="/static/img/written-by-heather-anderson-june-13-2024.png"
                    />
                    <div className="overlap-2">
                        <img
                            className="leilani-lopez-CEO-of"
                            alt="Leilani lopez CEO of"
                            src="/static/img/leilani-lopez-ceo-of-hifs-inc-brings-over-20-years-of-experti.png"
                        />
                        <img className="read-more" alt="Read more" src="/static/img/read-more-1x-png.png"/>
                    </div>
                    <img className="latest-PUBLICATIONS" alt="Latest PUBLICATIONS" src="/static/img/latest-publications.png"/>
                    <img className="TOPICS-TO-INSPIRE" alt="Topics TO INSPIRE" src="/static/img/topics-to-inspire.png"/>
                    <img className="element-d-a-f" alt="Element"
                         src="/static/img/4d-90-6a-0-3f-957-2fc-8-1ccb-1-1d-9-8f-9-5b-6-6d.png"/>
                    <img className="element-c-aa" alt="Element"
                         src="/static/img/1c-45911235-3aa-145-4cac-4-1b-4b-8e-7-6d-84.png"/>
                    <img className="element-b-ccb-d" alt="Element"
                         src="/static/img/7b-2ccb-5-0d-25005-4b-8-1dc-6a-5-1efe-960-4e.png"/>
                    <img
                        className="element-a-d-c-d"
                        alt="Element"
                        src="/static/img/82-8a-7d-9-5c-05-7d-0-5b-2-0dc-4-0c-8b-0-7f-3a-79.png"
                    />
                    <img className="sadie-scheffer" alt="Sadie scheffer" src="/static/img/sadie-scheffer.png"/>
                    <img
                        className="turning-a-gluten"
                        alt="Turning a gluten"
                        src="/static/img/turning-a-gluten-free-dream-into-bread-srsly.png"
                    />
                    <img
                        className="discovering-your"
                        alt="Discovering your"
                        src="/static/img/discovering-your-wealthy-self-with-regina-martinelli.png"
                    />
                    <img className="line" alt="Line" src="/static/img/line-14.png"/>
                    <img className="WOMENS-HEALTH" alt="Womens HEALTH" src="/static/img/women-s-health.png"/>
                    <img className="INFANTS-AND-TODDLERS" alt="Infants AND TODDLERS"
                         src="/static/img/infants-and-toddlers.png"/>
                    <img className="PARENTING" alt="Parenting" src="/static/img/parenting.png"/>
                    <img className="PRE-TEENS-AND-TEENS" alt="Pre TEENS AND TEENS" src="/static/img/pre-teens-and-teens.png"/>
                    <img className="SOCIAL-JUSTICE" alt="Social JUSTICE" src="/static/img/social-justice.png"/>
                    <img className="SEXUALITY" alt="Sexuality" src="/static/img/sexuality.png"/>
                    <img className="PREGNANCY-BIRTH" alt="Pregnancy BIRTH" src="/static/img/pregnancy-birth.png"/>
                    <img className="meet-attorney" alt="Meet attorney" src="/static/img/meet-attorney-patricia-de-fonte.png"/>
                    <img className="of-estate-planning" alt="Of estate planning"
                         src="/static/img/of-estate-planning-with-heart.png"/>
                    <img className="raana-kashani-gregg" alt="Raana kashani gregg" src="/static/img/raana-kashani-gregg.png"/>
                    <img
                        className="the-innovator-behind"
                        alt="The innovator behind"
                        src="/static/img/the-innovator-behind-lunaescent-shares-that-it-s-never-too-late.png"
                    />
                    <img className="RELATIONSHIPS" alt="Relationships" src="/static/img/relationships.png"/>
                    <img className="GARDENING" alt="Gardening" src="/static/img/gardening.png"/>
                    <img className="HOME-LIFE" alt="Home LIFE" src="/static/img/home-life.png"/>
                    <img className="TRAVEL" alt="Travel" src="/static/img/travel.png"/>
                    <img
                        className="leilani-lopez-2"
                        alt="Leilani lopez"
                        src="/static/img/leilani-lopez-championing-specialized-insurance-solutions-for-c.png"
                    />
                    <img
                        className="the-m-list-best-bay"
                        alt="The m list best bay"
                        src="/static/img/the-m-list-best-bay-area-cherry-picking.png"
                    />
                    <img
                        className="clean-and-effective"
                        alt="Clean and effective"
                        src="/static/img/clean-and-effective-deodorant-solved-by-sarah-ribner-of-piper-wa.png"
                    />
                    <img
                        className="empowering-parents"
                        alt="Empowering parents"
                        src="/static/img/empowering-parents-of-mixed-race-children-with-dr-jennifer-nobl.png"
                    />
                    <img
                        className="supporting-women-in"
                        alt="Supporting women in"
                        src="/static/img/supporting-women-in-ca-with-dr-amy-day-of-the-women-s-vitality.png"
                    />
                    <img
                        className="hormone-free-birth"
                        alt="Hormone free birth"
                        src="/static/img/hormone-free-birth-control-with-natural-cycles.png"
                    />
                    <img className="line-2" alt="Line" src="/static/img/line-16.png"/>
                    <img className="WHITE-BUTTON" alt="White BUTTON" src="/static/img/white-button-1x-png-1x-png.png"/>
                    <img className="line-3" alt="Line" src="/static/img/line-16.png"/>
                    <img className="group-2" alt="Group" src="/static/img/group-28.png"/>
                </div>
            </div>
        </TheMListContainer>
    );
};
export default Inspiration;
