import React, {useState} from 'react';
import styled from 'styled-components';
import CookieConsent from 'react-cookie-consent';


const CookieConsentContainer = styled.div`
    position: relative;
    width: 1280px; /* Fixed width */
    max-width: 100%; /* Ensures responsiveness on smaller screens */
    margin: 0 auto; /* Center the container */
`;

const CookieBar = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #2b373b;
    color: white;
    text-align: center;
    padding: 15px;
    z-index: 1000;
`;

const Button = styled.button`
    background-color: #f69b42;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    cursor: pointer;
    border-radius: 5px;

    &:hover {
        background-color: #e08d34;
    }
`;

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: ${({isVisible}) => (isVisible ? 'block' : 'none')};
    z-index: 999;
`;

const ModalContent = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
`;

const CookieConsentComponent = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleAccept = () => {
        // Handle acceptance logic
    };

    const handleSettings = () => {
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const handleSaveSettings = () => {
        // Save settings logic
        setIsModalOpen(false);
    };

    return (
        <CookieConsentContainer>
            <CookieConsent
                location="bottom"
                buttonText="Accept"
                declineButtonText="Cookie Settings"
                cookieName="myAwesomeCookieName2"
                style={{background: "#2b373b"}}
                buttonStyle={{background: "#f69b42", color: "white", fontSize: "13px", borderRadius: "5px"}}
                declineButtonStyle={{background: "#e08d34", color: "white", fontSize: "13px", borderRadius: "5px"}}
                enableDeclineButton
                onAccept={handleAccept}
                onDecline={handleSettings}
            >
                This website uses cookies to enhance the user experience.
            </CookieConsent>

            <ModalOverlay isVisible={isModalOpen} onClick={handleModalClose}>
                <ModalContent onClick={(e) => e.stopPropagation()}>
                    <h2>Cookie Settings</h2>
                    <p>Adjust your cookie settings below:</p>
                    <div>
                        <input type="checkbox" id="necessary" name="necessary" checked disabled/>
                        <label htmlFor="necessary">Necessary Cookies (always enabled)</label>
                    </div>
                    <div>
                        <input type="checkbox" id="analytics" name="analytics"/>
                        <label htmlFor="analytics">Analytics Cookies</label>
                    </div>
                    <div>
                        <input type="checkbox" id="marketing" name="marketing"/>
                        <label htmlFor="marketing">Marketing Cookies</label>
                    </div>
                    <div style={{marginTop: '20px'}}>
                        <Button onClick={handleSaveSettings}>Save Settings</Button>
                        <Button onClick={handleModalClose}
                                style={{backgroundColor: '#777', marginLeft: '10px'}}>Cancel</Button>
                    </div>
                </ModalContent>
            </ModalOverlay>
        </CookieConsentContainer>
    );
};

export default CookieConsentComponent;
