import React from 'react';
import styled from 'styled-components';

const PartnersContainer = styled.div`
    position: relative;
    width: 1280px;
  padding: 20px;
  background-color: #ffffff;
`;

const Paragraph = styled.p`
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
`;

const Title = styled.h2`
  font-size: 20px;
  color: #000;
  margin-bottom: 20px;
`;

const Link = styled.a`
  color: #1155cc;
  text-decoration: underline;
`;

const Partners = () => {
  return (
    <PartnersContainer>
      <Title>For Partner Brands</Title>
      <Paragraph>
        If you are a mom-facing product, service, nonprofit, we’d love to hear from you! There are many ways we partner with businesses and organizations to fund our community:
      </Paragraph>
      <Paragraph>
        <Link href="https://www.google.com/url?q=http://www.heatherlynnanderson.com&sa=D&source=editors&ust=1719980949017315&usg=AOvVaw0JsBPTzrlGfQv4PhlL2ocy">Business consulting</Link> on user acquisition, social media strategy, organic growth strategy, or strategic partnerships.
      </Paragraph>
      <Paragraph>Sponsored blog features</Paragraph>
      <Paragraph>Sponsored marketing campaigns within our forums, social media channels, and mailing list</Paragraph>
      <Paragraph>Sponsored Club memberships (provide funding so that we can offer additional Club membership scholarships to women Founders who need the help in exchange for visibility and marketing)</Paragraph>
      <Paragraph>Inquire about any of the above through our Founder’s consulting site, or via email at partners at themamahood.co.</Paragraph>
    </PartnersContainer>
  );
};

export default Partners;
