import React, { useState } from 'react';
import styled from 'styled-components';

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const ModalContainer = styled.div`
  width: 80%;
  max-width: 800px;
  height: 90%;
  background-color: #f8f7f6;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  overflow-y: auto;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;

const Header = styled.h1`
  text-align: center;
  font-size: 36px;
  color: #333;
`;

const SubHeader = styled.h2`
  text-align: center;
  font-size: 24px;
  color: #555;
  margin-bottom: 20px;
`;

const DonationForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
`;

const Label = styled.label`
  margin-bottom: 5px;
  font-size: 18px;
  color: #555;
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
`;

const TextArea = styled.textarea`
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
`;

const Button = styled.button`
  padding: 10px;
  background-color: #54bab2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;

  &:hover {
    background-color: #43a5a0;
  }
`;

const GoalContainer = styled.div`
  margin: 20px 0;
  text-align: center;
  width: 100%;
`;

const GoalBar = styled.div`
  background-color: #ddd;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
`;

const GoalProgress = styled.div`
  background-color: #f69b429e;
  height: 20px;
  width: ${props => props.progress}%;
`;

const FundUsage = styled.div`
  margin: 20px 0;
  width: 100%;
`;

const FundItem = styled.div`
  margin-bottom: 10px;
`;

const FundTitle = styled.h3`
  font-size: 18px;
  color: #333;
`;

const FundDescription = styled.p`
  font-size: 16px;
  color: #555;
`;

const DonateModal = ({ showModal, closeModal }) => {
  const [donationAmount, setDonationAmount] = useState('');
  const [donorName, setDonorName] = useState('');
  const [message, setMessage] = useState('');
  const [totalRaised, setTotalRaised] = useState(2500); // Example starting amount
  const goalAmount = 5000;

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add logic to process donation
    const amount = parseFloat(donationAmount);
    if (!isNaN(amount) && amount > 0) {
      setTotalRaised(totalRaised + amount);
      setDonationAmount('');
      setDonorName('');
      setMessage('');
    }
  };

  if (!showModal) return null;

  const progress = (totalRaised / goalAmount) * 100;

  return (
    <ModalBackground>
      <ModalContainer>
        <CloseButton onClick={closeModal}>&times;</CloseButton>
        <Header>Support The Mamahood Community</Header>
        <SubHeader>Your contributions make a difference!</SubHeader>

        <DonationForm onSubmit={handleSubmit}>
          <Label htmlFor="donationAmount">Donation Amount</Label>
          <Input
            type="number"
            id="donationAmount"
            value={donationAmount}
            onChange={(e) => setDonationAmount(e.target.value)}
          />

          <Label htmlFor="donorName">Your Name</Label>
          <Input
            type="text"
            id="donorName"
            value={donorName}
            onChange={(e) => setDonorName(e.target.value)}
          />

          <Label htmlFor="message">Message (Optional)</Label>
          <TextArea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />

          <Button type="submit">Donate Now</Button>
        </DonationForm>

        <GoalContainer>
          <SubHeader>Fundraising Goal: ${goalAmount}</SubHeader>
          <GoalBar>
            <GoalProgress progress={progress} />
          </GoalBar>
          <p>${totalRaised} raised out of ${goalAmount}</p>
        </GoalContainer>

        <FundUsage>
          <SubHeader>How Your Donation Helps</SubHeader>
          <FundItem>
            <FundTitle>Community Events</FundTitle>
            <FundDescription>Organizing community-building events and activities for moms.</FundDescription>
          </FundItem>
          <FundItem>
            <FundTitle>Support Services</FundTitle>
            <FundDescription>Providing support services and resources to moms in need.</FundDescription>
          </FundItem>
          <FundItem>
            <FundTitle>Educational Programs</FundTitle>
            <FundDescription>Funding educational programs and workshops for personal and professional growth.</FundDescription>
          </FundItem>
        </FundUsage>
      </ModalContainer>
    </ModalBackground>
  );
};

export default DonateModal;
