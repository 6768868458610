// src/components/InspirationSection.jsx
import React from 'react';
import styled from 'styled-components';
import inspirationImage from '../assets/Abramoss-74_postpartum_doula_breastfeeding.png';



const SectionWrapper = styled.div`
    display: flex;
    width: 1280px;
    height: 658px;
    opacity: 1;
    top: 164px;
    position: relative; /* This ensures that absolutely positioned children are relative to this container */
`;

const Image = styled.img`
    width: 640px;
    height: 494px;
    object-fit: cover;
    margin-right: 20px;
`;

const TextContainer = styled.div`
    max-width: 500px;
    text-align: left;
`;

const Heading = styled.h2`
    font-family: 'Oswald', sans-serif;
    font-size: 36px;
    color: #484949;
    margin: 0 0 20px 0;
`;

const Text = styled.p`
    font-family: 'Nunito Sans', sans-serif;
    font-size: 20px;
    color: #484949;
    margin: 0 0 20px 0;
`;

const Button = styled.button`
    background: transparent;
    border: 2px solid #484949;
    color: #484949;
    font-family: 'Oswald', sans-serif;
    font-size: 16px;
    padding: 10px 20px;
    cursor: pointer;

    &:hover {
        background: rgba(72, 73, 73, 0.1);
    }
`;

const InspirationSection = () => {
    return (
            <SectionWrapper>
                <Image src={inspirationImage} alt="Inspiration"/>
                <TextContainer>
                    <Heading>Inspiration</Heading>
                    <Text>
                        Your source for helpful, and inspirational mothering articles by moms for moms.
                    </Text>
                    <Button>Read More</Button>
                </TextContainer>
            </SectionWrapper>
    );
};

export default InspirationSection;
