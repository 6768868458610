import React, {useEffect, useState} from 'react';

const RSSFeed = () => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        const fetchRSSFeed = async () => {
            try {
                const response = await fetch('https://themamahood-co.squarespace.com/blog?format=rss');
                const text = await response.text();
                const parser = new DOMParser();
                const xml = parser.parseFromString(text, 'text/xml');
                const items = Array.from(xml.querySelectorAll('item')).map(item => ({
                    title: item.querySelector('title').textContent,
                    link: item.querySelector('link').textContent,
                    description: item.querySelector('description').textContent,
                    pubDate: item.querySelector('pubDate').textContent
                }));
                setItems(items);
            } catch (error) {
                console.error('Error fetching or parsing RSS feed:', error);
            }
        };

        fetchRSSFeed();
    }, []);

    return (
        <div>
            <h1>RSS Feed</h1>
            <ul>
                {items.map((item, index) => (
                    <li key={index}>
                        <a href={item.link} target="_blank" rel="noopener noreferrer">
                            <h2>{item.title}</h2>
                        </a>
                        <p>{item.description}</p>
                        <small>{item.pubDate}</small>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default RSSFeed;
