import React from "react";
import "./style.css";
import styled from "styled-components";
import ImageSection from "./ImageSection";

const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    min-height: 100vh;
`;

const JoinPage = () => {
    return (
        <SectionContainer>
            <ImageSection/>

            <div className="join">
                <div className="div">
                    <div className="overlap">
                        <img className="rectangle" alt="Rectangle" src="/static/img/rectangle-92.png"/>
                        <img className="element" alt="Element"
                             src="/static/img/340-2d-5-0e-871-1ebc-1ba-5-8ecee-793-4d-1f-1.png"/>
                        <img className="img" alt="Img" src="/static/img/file.png"/>
                        <img
                            className="the-club-by-the"
                            alt="The club by the"
                            src="/static/img/the-club-by-the-mamahood-is-a-community-and-incubator-for-women.png"
                        />
                        <img className="rectangle-2" alt="Rectangle" src="/static/img/rectangle-5-2-1x-png.png"/>
                        <img className="CHECK-IT-OUT" alt="Check IT OUT" src="/static/img/check-it-out.png"/>
                        <img className="level-up-your-career" alt="Level up your career"
                             src="/static/img/level-up-your-career.png"/>
                    </div>
                    <div className="overlap-group">
                        <div className="overlap-2">
                            <img className="rectangle-3" alt="Rectangle" src="/static/img/rectangle-5-1x-png.png"/>
                            <img className="group" alt="Group" src="/static/img/group-30.png"/>
                        </div>
                        <div className="overlap-3">
                            <img className="rectangle-4" alt="Rectangle" src="/static/img/rectangle-5-1.png"/>
                            <img className="group-2" alt="Group" src="/static/img/group-29.png"/>
                        </div>
                        <img className="group-3" alt="Group" src="/static/img/group-31.png"/>
                        <img className="NEW" alt="New" src="/static/img/new.png"/>
                        <div className="overlap-4">
                            <img className="rectangle-5" alt="Rectangle" src="/static/img/rectangle-91.png"/>
                            <img
                                className="we-are-a-free"
                                alt="We are a free"
                                src="/static/img/we-are-a-free-membership-based-mama-community-find-your-tri.png"
                            />
                        </div>
                    </div>
                    <img className="group-4" alt="Group" src="/static/img/group-20-1x-png-1x-png.png"/>
                    <img
                        className="stock-photo-working"
                        alt="Stock photo working"
                        src="/static/img/stock-photo-working-mother-using-laptop-and-playing-with-baby-bo.png"
                    />
                    <img className="element-mamas-in" alt="Element mamas in" src="/static/img/100k-mamas-in-60-groups.png"/>
                    <img
                        className="stock-photo-group-of"
                        alt="Stock photo group of"
                        src="/static/img/stock-photo-group-of-mature-friends-walking-along-path-through-y.png"
                    />
                    <div className="overlap-5">
                        <img className="membership-perks" alt="Membership perks" src="/static/img/membership-perks.png"/>
                        <img
                            className="deals-and-offers"
                            alt="Deals and offers"
                            src="/static/img/deals-and-offers-from-mamahood-s-club-for-women-entrepreneurs.png"
                        />
                        <img
                            className="mamahood-members"
                            alt="Mamahood members"
                            src="/static/img/mamahood-members-newsletter-to-inspire-support-and-nourish.png"
                        />
                        <img
                            className="in-person-and-online"
                            alt="In person and online"
                            src="/static/img/in-person-and-online-events-to-learn-connect-and-grow.png"
                        />
                        <img className="newsletter" alt="Newsletter" src="/static/img/newsletter-1.png"/>
                        <img className="gather" alt="Gather" src="/static/img/gather.png"/>
                        <img className="deals" alt="Deals" src="/static/img/deals.png"/>
                    </div>
                    <img
                        className="we-have-groups-for"
                        alt="We have groups for"
                        src="/static/img/we-have-groups-for-buying-selling-giving-receiving-and-chatt.png"
                    />
                    <img className="IRL-meet-ups" alt="Irl meet ups" src="/static/img/irl-meet-ups.png"/>
                    <img
                        className="the-mamahood-TM"
                        alt="The mamahood TM"
                        src="/static/img/the-mamahood-tm-consists-of-20-meet-up-groups-for-facilitatin.png"
                    />
                    <img className="WHITE-BUTTON" alt="White BUTTON" src="/static/img/white-button-1x-png-1x-png-1x-png.png"/>
                    <img className="group-5" alt="Group" src="/static/img/group-28.png"/>
                </div>
            </div>
        </SectionContainer>
    );
};
export default JoinPage;
