// src/components/TheClubPage.jsx

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const HeaderWrapper = styled.header`
  font-size: 16px;
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 1000;

  .header-mobile {
    background-color: #fff;
  }

  .link-list__link {
    color: #000000;
    &:hover {
      color: #000000;
    }
  }

  .dropdown__trigger {
    color: #000000 !important;
    &:hover {
      color: #000000;
    }
  }

  .dropdown__item a,
  .user__login a {
    color: #000000;
  }

  .hamburger__slice {
    background-color: #000000;
  }

  @media (max-width: 767px) {
    .link-list__link,
    .dropdown__item a,
    .user__login a {
      text-align: center;
      color: #293039;
    }

    .header__content--mobile {
      text-align: center;
      background-color: #fff;
      padding: 20px 0;
    }
  }
`;

const LogoImage = styled.img`
  display: block;
  width: 150px;
`;

const Section = styled.section`
  background-color: rgba(236, 240, 241, 0);
  background-position: center;
  background-image: url('/static/img/Md0q0npRcy04rBA7ONFQ_PalePinkHeatherScooted.png');
  padding-top: 40px;
  padding-bottom: 40px;

  @media (min-width: 768px) {
    padding-top: 200px;
    padding-bottom: 200px;
  }
`;

const Container = styled.div`
  max-width: calc(1260px + 10px + 10px);
  padding-right: 10px;
  padding-left: 10px;

  @media (min-width: 768px) {
    max-width: calc(1260px + 40px + 40px);
    padding-right: 40px;
    padding-left: 40px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
`;

const Block = styled.div`
  margin: 0;
  padding: 0;
  border: 4px none black;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;

  @media (max-width: 767px) {
    text-align: left;
  }
`;

const Image = styled.img`
  width: 100%;
  border-radius: 4px;
`;

const TextBlock = styled.div`
  text-align: left;
  margin: 0;
  padding: 20px 0;

  @media (max-width: 767px) {
    text-align: center;
  }
`;

const Button = styled(Link)`
  display: inline-block;
  border-color: #000000;
  border-radius: 4px;
  color: #ffffff;
  background-color: #000000;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  margin-top: 1rem;

  &:hover {
    background-color: #333;
  }
`;

const TheMListContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`;

const FirstSection = () => {
  return (
    <TheMListContainer>
      <HeaderWrapper>
        <div className="announcements"></div>
        <div className="header__wrap">
          <div className="header__content header__content--desktop background-light">
            <div className="container header__container media">
              <div className="header__block header__block--logo header__block--show">
                <Link to="/theclub/">
                  <LogoImage
                    src="/static/img/MqT5L6XOSxGbc9ntzELa_The-Club-The-Mamahood-Logo-V1_The_Club_Logo.jpg"
                    alt="Header Logo"
                  />
                </Link>
              </div>

              <div className="hamburger hidden--desktop">
                <div className="hamburger__slices">
                  <div className="hamburger__slice hamburger--slice-1"></div>
                  <div className="hamburger__slice hamburger--slice-2"></div>
                  <div className="hamburger__slice hamburger--slice-3"></div>
                  <div className="hamburger__slice hamburger--slice-4"></div>
                </div>
              </div>
              <div className="header__block header__switch-content header__block--menu media__body">
                <div className="link-list justify-content-right">
                  <Link className="link-list__link" to="/theclub/membership" rel="noopener">
                    Membership
                  </Link>
                  <Link className="link-list__link" to="/theclub/magazine" rel="noopener">
                    The Magazine
                  </Link>
                </div>
              </div>
              <div className="header__block header__switch-content header__block--user">
                <div className="user">
                  <span className="user__login">
                    <Link to="/accounts/login/">Login</Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="header__content header__content--mobile background-light"></div>
        </div>
      </HeaderWrapper>

      <main>
        <Section>
          <Container>
            <Row>
              <Block>
                <Image src="/static/img/bAve5pJGQWSGbSPULdnx_The-Club-The-Mamahood-Logo-V1_The_Club_Logo.png" alt="" />
              </Block>

              <TextBlock>
                <h1>
                  <span style={{ fontFamily: "'Dancing Script', cursive", color: '#293039' }}>Welcome to The Club!</span>
                </h1>
                <p>
                  <strong style={{ color: '#2b4a4e' }}>
                    If you’re the CEO, Founder, or Co-Founder of something awesome - and you wish you had peers with whom
                    you could brainstorm, collaborate and grow, we've got you!&nbsp;
                  </strong>
                </p>
                <Button to="/resource_redirect/landing_pages/1862632">I'M READY TO JUMP IN!</Button>
              </TextBlock>
            </Row>
          </Container>
        </Section>
      </main>
    </TheMListContainer>
  );
};

export default FirstSection;
