// src/components/ImageSection.jsx
import React from 'react';
import styled, {keyframes} from 'styled-components';
import backgroundImage from '../../assets/Lau-55.png';
import {Link} from 'react-router-dom';


// Colors
const borderColor = '#82837F';
const borderHoverColor = 'rgba(246,155,66,0.62)';
const textColor = '#82837F';

const ImageWrapper = styled.div`
    width: 1280px;
    height: 697px;
    background: transparent url(${backgroundImage}) 0% 0% no-repeat padding-box;
    opacity: 1;
    position: relative; /* This ensures that absolutely positioned children are relative to this container */
`;

const TextOverlay = styled.div`
    position: absolute;
    top: 141px;
    user-select: none;
    left: 142px;
    width: 729px;
    height: 231px;

    text-align: left;
    display: flex;
    z-index: 2; /* Ensure the text is above the accent rectangle */
    letter-spacing: 0px; /* Character spacing */
    line-height: 69px; /* Line spacing */

    h1 {
        color: #484949;
        font-family: 'Nunito Sans', sans-serif;
        font-size: 50px;

    }
`;

const PhotoCreditTextOverlay = styled.div`
    position: relative;
    left: 20px;
    width: 100%;
    height: 697px;
    color: #484949;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 12px;
    text-align: left;
    display: flex;
    top: 640px;
    z-index: 2; /* Ensure the text is above the accent rectangle */
    letter-spacing: 0px; /* Character spacing */
    line-height: 69px; /* Line spacing */
    user-select: none;

`;

const AccentRectangle = styled.div`
    position: absolute;
    top: 180px;
    left: 132px;
    width: 578px;
    height: 28px;
    background-color: #FFD3B6; /* Replace with the hex value from the design */
    opacity: 1;
`;

// Define keyframes for the winking animation
const wink = keyframes`
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
`;


// Style the button with the winking animation
const WinkingButton = styled(Link)`
    position: absolute;
    top: 389px;
    left: 142px;
    width: 91px;
    height: 42px;
    border: 2px solid ${borderColor};
    border-radius: 0px;
    cursor: pointer;
    font-family: 'Oswald', sans-serif;
    font-size: 24px;
    color: ${textColor};
    text-align: center;
    line-height: 36px; /* Adjust line height to vertically center the text */
    transition: transform 0.2s, background-color 0.2s;

    &:hover {
        transform: scale(1.1);
        border: 2px solid ${borderHoverColor};
    }

`;


const ButtonOverlay = styled(Link)`
    position: absolute;
    top: 389px;
    left: 142px;
    width: 91px;
    height: 42px;
    background: rgba(255, 255, 255, 0);
    border: 2px solid ${borderColor};
    border-radius: 0px;
    cursor: pointer;
    font-family: 'Oswald', sans-serif;
    font-size: 24px;
    color: ${textColor};
    text-align: center;
    line-height: 36px; /* Adjust line height to vertically center the text */

    &:hover {
        background: #f0f0f0;
    }
`;

const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    top: 104px;
    width: 100%;
`;

const ImageSection = () => {
    return (
        <ImageContainer>
            <ImageWrapper>
                <TextOverlay>
                    <h1>
                        The Mamahood is a community where Supportive and Empowering Moms go.
                    </h1></TextOverlay>
                <PhotoCreditTextOverlay>
                    Photo by: Lorem ipsum
                </PhotoCreditTextOverlay>
                <AccentRectangle/>
                <WinkingButton to="/join">
                    JOIN
                </WinkingButton>
            </ImageWrapper>
        </ImageContainer>
    );
};

export default ImageSection;
