// src/Settings.jsx
import React, { useState } from 'react';
import styled from 'styled-components';

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f7f7f7;
  min-height: 100vh;
`;

const SettingsHeader = styled.h1`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 28px;
  color: #484949;
  margin-bottom: 20px;
`;

const SettingsForm = styled.form`
  width: 100%;
  max-width: 600px;
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0px 3px 6px #00000010;
  border-radius: 10px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Label = styled.label`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  color: #707070;
  margin-bottom: 5px;
`;

const Input = styled.input`
  padding: 10px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  color: #484949;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const Select = styled.select`
  padding: 10px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  color: #484949;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const SaveButton = styled.button`
  background-color: #54bab2;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;

  &:hover {
    background-color: #43a5a0;
  }
`;

const Settings = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    language: 'en',
    timezone: 'UTC',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    console.log(formData);
  };

  return (
    <SettingsContainer>
      <SettingsHeader>Settings</SettingsHeader>
      <SettingsForm onSubmit={handleSubmit}>
        <FormGroup>
          <Label htmlFor="username">Username</Label>
          <Input
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="email">Email</Label>
          <Input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="language">Language</Label>
          <Select
            id="language"
            name="language"
            value={formData.language}
            onChange={handleChange}
          >
            <option value="en">English</option>
            <option value="es">Spanish</option>
            <option value="fr">French</option>
            <option value="de">German</option>
            {/* Add more languages as needed */}
          </Select>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="timezone">Timezone</Label>
          <Select
            id="timezone"
            name="timezone"
            value={formData.timezone}
            onChange={handleChange}
          >
            <option value="UTC">UTC</option>
            <option value="PST">Pacific Standard Time</option>
            <option value="EST">Eastern Standard Time</option>
            {/* Add more timezones as needed */}
          </Select>
        </FormGroup>
        <SaveButton type="submit">Save Changes</SaveButton>
      </SettingsForm>
    </SettingsContainer>
  );
};

export default Settings;
