import React, {useState} from 'react';
import "./style.css";
import styled from "styled-components";
import {Link} from "react-router-dom";
import ImageSection from "../TheMListOpen/ImageSection";
import NewSection from "../TheMListOpen/NewSection";
import MListSection from "../TheMListOpen/MListSection";


const TheMListContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 1280px;
`;

const Container = styled.div`
    width: 100%;
    overflow: hidden;


    h1 {
        font-family: 'Nunito Sans', sans-serif;
        font-size: 50px;
        color: #707070;
        text-align: center;
        margin-bottom: 20px;
    }

    h2 {
        font-size: 35px;
        color: #82837F;
        margin-top: 30px;
        text-align: center;

        margin-bottom: 15px;
    }
`;

const Content = styled.div`
    display: flex;
    position: relative;
    overflow: hidden;
    max-height: ${({isOpen}) => (isOpen ? '2000px' : '0')};
    transition: max-height 0.3s ease-in-out;
    background-color: #ffffff;
    padding: ${({isOpen}) => (isOpen ? '20px' : '0 20px')};
`;

const Column = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
`;

const ColumnContainer = styled.div`
    align-items: flex-start;
    display: flex;
    position: relative;
    height: 1200px;
    flex-direction: row;
    gap: 150px;
`;

const SectionHeader = styled.div`
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
    font-size: 24px;
    color: #707070;
    display: flex;
    position: relative;
    flex-direction: row;
    margin-bottom: 20px;
`;

const ColumnSection = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: left;
`;

const Header = styled.div`
    position: relative;
    height: 100px;
    top: 10px;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    gap: 10px;
`;

const OpenListContainer = styled.div`
    position: relative;
    background-color: #ffffff;
    font-weight: 400;
    font-size: 25px;
    color: #F69B42;
    cursor: pointer;
    display: flex;
    align-content: center;
    justify-content: center;
`;

const Links = styled.div`
    position: relative;
    display: flex;
    left: 50px;
    flex-direction: column;
    margin-bottom: 20px;
    gap: 10px;
`;

const DropDownItemContainer = styled.div`
    display: flex;
    position: relative;
`;

const TextLink = styled(Link)`
    position: relative;
    display: inline-block;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 16px;
    line-height: 17px;
    font-weight: 500;
    color: #707070;
    text-decoration: none;
    padding-bottom: 2px; /* To create space for the color block */
    z-index: 1; /* Ensure the text is on top */

    &:before {
        content: '';
        position: absolute;
        right: 5px;
        bottom: 0;
        width: calc(100% - 0px); /* Subtract 10px from 100% width */
        top: 7px;
        height: 10px;
        background: rgba(246, 155, 66, 0.62);
        opacity: 0;
        transition: opacity 0.3s ease;
        z-index: -1;
    }

    &:hover:before {
        opacity: 1;
    }
`;

const Arrow = styled.div`
    position: relative;
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-top: 25px solid #F69B42;
    transition: transform 0.4s ease;
    transform: ${({isOpen}) => (isOpen ? 'rotate(-180deg)' : 'rotate(0deg)')};
`;


const CommunityPage = () => {


    const [isOpen, setIsOpen] = useState(false);

    const toggleSection = () => {
        setIsOpen(!isOpen);
    };

    const sectionLinks = {}

    return (
        <TheMListContainer>
            <ImageSection/>
            <Container>
                <h1>Your Online Community Groups</h1>
                <h2>Join a topic group or meet-up</h2>
                <Header onClick={toggleSection}>
                    <OpenListContainer>{isOpen ? 'Close' : 'Open'} the list</OpenListContainer>
                    <Arrow isOpen={isOpen}/>
                </Header>
                <Content isOpen={isOpen}>
                    <ColumnContainer>
                        <Column>
                            <ColumnSection>
                                <SectionHeader>
                                    Parenting
                                </SectionHeader>
                                <Links>
                                    <DropDownItemContainer><TextLink
                                        to="/partner">Homeschooling</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/theclub">Juicy Mamas and Sex Positive
                                        Parenting</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/sitemap">Mamas of Mixed Race
                                        Children</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/privacy">Mamas of Multiples (SF Bay
                                        Area)</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/policies">Pregnancy, Birth & Breastfeeding
                                        Support (SF Bay Area)</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/contact">Screen Time (SF Bay Area)</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/contact">Mamas of Preemies ( SF Bay Area
                                        )</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/contact">Stepmama Support (SF Bay
                                        Area)</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/contact">LGBTQ+ Families and Allies (SF Bay
                                        Area)</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/contact">Surrogate Mama Network (SF Bay Area
                                        )</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/contact">Separation, Custody & Co-Parenting
                                        Support (SF Bay Area)</TextLink></DropDownItemContainer>
                                </Links>
                                <SectionHeader>
                                    Resources
                                </SectionHeader>
                                <Links>
                                    <DropDownItemContainer><TextLink to="/partner">Travel Mamas (SF Bay
                                        Area) </TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/theclub">Lindsay Wildlife Family
                                        Memberships</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/sitemap">All Things Home and Garden (SF Bay
                                        Area)</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/privacy">Furbabies and More (SF Bay
                                        Area)</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/partner">Children and The Arts (SF Bay
                                        Area)</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/theclub">Things To Do With Kiddos </TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/sitemap">Job Board + Career Guidance (SF Bay
                                        Area)</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/privacy">Swap Meet (SF Area
                                        Bay)</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/partner">Giving Circle (SF Bay
                                        Area)</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/theclub">Childcare Network ( SF Bay Area
                                        )</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/sitemap">Little
                                        Bookworms </TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/privacy">Toy Talk - Toy and other Enrichment
                                        Recommendations for Ages 0-99</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/partner">Special Needs Professional and Parent
                                        Connection (SF Bay Area)
                                    </TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/theclub">Camping + Outdoor Adventures (RVs,
                                        Hiking, Biking, Rafting, etc.)
                                    </TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/sitemap">Housing + Vacation Rentals (SF Bay
                                        Area +)</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/privacy">Feeding the Family (SF Bay
                                        Area)</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/partner">Coronavirus (COVID-19) Support for
                                        Bay Area Families</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/theclub">Parties & Events (SF Bay
                                        Area)</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/sitemap">Photography Network (SF Bay
                                        Area)</TextLink></DropDownItemContainer>

                                </Links>
                            </ColumnSection>
                        </Column>
                        <Column>
                            <ColumnSection>
                                <SectionHeader>
                                    Mamahood Chapters
                                </SectionHeader>
                                <Links>
                                    <DropDownItemContainer><TextLink to="/partner">The Mamahood - SF Bay Area</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/theclub">The Mamahood - Sacramento
                                        Valley</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/sitemap">The Mamahood - LA County + SoCal
                                        (Brand new!!)</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/privacy">The Mamahood -
                                        Global</TextLink></DropDownItemContainer>

                                </Links>
                            </ColumnSection>
                            <ColumnSection>
                                <SectionHeader>
                                    Meet-Ups
                                </SectionHeader>
                                <Links>
                                    <DropDownItemContainer><TextLink to="/partner">Hiking with Toddlers (SF Bay
                                        Area)</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/theclub">Crafty Mamas (SF Bay Area)</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/sitemap">Contra Costa Meet-Up for Mamas Age
                                        40+ With Littles</TextLink></DropDownItemContainer>

                                </Links>
                            </ColumnSection>
                            <ColumnSection>
                                <SectionHeader>
                                    Self Care
                                </SectionHeader>
                                <Links>
                                    <DropDownItemContainer><TextLink to="/partner">Beauty by the Bay ( SF Bay Area
                                        )</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/theclub">Creating Space (SF Bay
                                        Area)</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/sitemap">Fashion Boutique (SF Bay
                                        Area)</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/privacy">Fitness (SF Bay
                                        Area)</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/partner">Love and Relationships (SF Bay
                                        Area)</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/theclub">Survivor Stories of Sexual Assault or
                                        Abuse</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/sitemap">Single Mamas (SF Bay Area)</TextLink></DropDownItemContainer>
                                </Links>
                            </ColumnSection>
                            <ColumnSection>
                                <SectionHeader>
                                    Education
                                </SectionHeader>
                                <Links>
                                    <DropDownItemContainer><TextLink to="/partner">Mamas and Higher Education (SF Bay
                                        Area)</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/theclub">Moneymamas (SF Bay
                                        Area)</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/sitemap">Social Justice Academy (SJA) by The
                                        Mamahood</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/privacy">Parent Education (SF Bay
                                        Area)</TextLink></DropDownItemContainer>
                                </Links>
                            </ColumnSection>
                            <ColumnSection>
                                <SectionHeader>
                                    Health and Healing
                                </SectionHeader>
                                <Links>
                                    <DropDownItemContainer><TextLink to="/partner">Mental Health Support for
                                        Mamas</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/theclub">Grief Support (SF Bay
                                        Area) </TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/sitemap">Health & Wellness (SF Bay
                                        Area)</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/privacy">Postpartum Depression/Anxiety
                                        (PPD/PPA) Support (SF Bay Area)</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/partner">Mamas in
                                        Recovery</TextLink></DropDownItemContainer>
                                    <DropDownItemContainer><TextLink to="/theclub">Cancer Support for
                                        Mamas</TextLink></DropDownItemContainer>
                                </Links>
                            </ColumnSection>
                        </Column>
                    </ColumnContainer>
                </Content>
            </Container>
            <NewSection/>
            <MListSection/>

            <div className="community-list">
                <div className="div">
                    <div className="overlap-group">
                        <img className="rectangle" alt="Rectangle" src="/static/img/rectangle-36-1x-png.png"/>
                        <img className="img" alt="Rectangle" src="/static/img/rectangle-78.png"/>
                        <img className="connect-with-your" alt="Connect with your"
                             src="/static/img/connect-with-your-local-tribe.png"/>
                        <img className="SF-BAY-AREA-LOS" alt="Sf BAY AREA LOS"
                             src="/static/img/sf-bay-area-los-angeles-sacremento.png"/>
                        <img className="in-california" alt="In california" src="/static/img/in-california.png"/>
                        <img className="upcoming-events" alt="Upcoming events" src="/static/img/upcoming-events.png"/>
                        <img className="group" alt="Group" src="/static/img/group-34.png"/>
                        <img className="meet-people-learn" alt="Meet people learn"
                             src="/static/img/meet-people-learn-grow-thrive.png"/>
                    </div>
                    <div className="overlap">
                        <img className="line" alt="Line" src="/static/img/line-3-2.png"/>
                        <img className="rectangle-2" alt="Rectangle" src="/static/img/rectangle-27.png"/>
                        <img className="abramoss" alt="Abramoss"
                             src="/static/img/abramoss-74-postpartum-doula-breastfeeding.png"/>
                        <img className="post-birth-body" alt="Post birth body"
                             src="/static/img/post-birth-body-rehab-as-self-care.png"/>
                        <img
                            className="so-many-mamas-have"
                            alt="So many mamas have"
                            src="/static/img/so-many-mamas-have-been-told-that-peeing-their-pants-is-just-wh.png"
                        />
                        <img className="with-natalie-garay" alt="With natalie garay"
                             src="/static/img/with-natalie-garay.png"/>
                        <img className="READ-MORE" alt="Read MORE" src="/static/img/read-more.png"/>
                        <img className="FEATURE-STORY" alt="Feature STORY" src="/static/img/feature-story.png"/>
                    </div>
                    <img className="group-2" alt="Group" src="/static/img/group-20.png"/>
                    <div className="overlap-2">
                        <img className="rectangle-3" alt="Rectangle" src="/static/img/rectangle-51-1x-png.png"/>
                        <img className="welcome-mama-glad" alt="Welcome mama glad"
                             src="/static/img/welcome-mama-glad-you-are-here.png"/>
                    </div>
                    <div className="line-wrapper">
                        <img className="line-2" alt="Line" src="/static/img/line-3-2.png"/>
                    </div>
                    <img
                        className="teaser-text-about"
                        alt="Teaser text about"
                        src="/static/img/teaser-text-about-active-meet-ups-and-why-they-rock.png"
                    />
                    <img
                        className="stock-photo-female"
                        alt="Stock photo female"
                        src="/static/img/stock-photo-female-host-talking-to-a-guest-friend-on-a-podcast-r.png"
                    />
                    <img className="date-time-how-to" alt="Date time how to"
                         src="/static/img/date-time-how-to-join.png"/>
                    <img className="MOVING-TOGETHER" alt="Moving TOGETHER" src="/static/img/moving-together.png"/>
                    <img className="LIVE-ONLINE-EVENT" alt="Live ONLINE EVENT" src="/static/img/live-online-event.png"/>
                    <img className="WHITE-BUTTON" alt="White BUTTON" src="/static/img/white-button-1x-png.png"/>
                    <img className="WHITE-BUTTON-2" alt="White BUTTON" src="/static/img/white-button-1-1x-png.png"/>
                    <img className="BAY-AREA-experiences" alt="Bay AREA experiences"
                         src="/static/img/bay-area-experiences.png"/>
                    <img className="upcoming-event" alt="Upcoming event" src="/static/img/upcoming-event.png"/>
                    <div className="overlap-3">
                        <img className="screenshot" alt="Screenshot"
                             src="/static/img/screenshot-2024-06-06-at-11-06-41-am.png"/>
                        <img className="rectangle-4" alt="Rectangle" src="/static/img/rectangle-42.png"/>
                        <img className="GLOW-SALON" alt="Glow SALON" src="/static/img/glow-salon.png"/>
                        <img className="special-occasion" alt="Special occasion"
                             src="/static/img/special-occasion-hair-and-make-up.png"/>
                        <img className="SACRAMENTO" alt="Sacramento" src="/static/img/sacramento.png"/>
                    </div>
                    <div className="overlap-4">
                        <img className="screenshot" alt="Screenshot"
                             src="/static/img/screenshot-2024-06-06-at-11-01-49-am.png"/>
                        <img className="rectangle-5" alt="Rectangle" src="/static/img/rectangle-42.png"/>
                        <img className="SEASONS-LIFE" alt="Seasons LIFE" src="/static/img/seasons-life-coaching.png"/>
                        <img
                            className="coaching-for-women"
                            alt="Coaching for women"
                            src="/static/img/coaching-for-women-going-thorugh-life-changes.png"
                        />
                        <img className="SANTA-MONICA" alt="Santa MONICA" src="/static/img/santa-monica.png"/>
                    </div>
                    <div className="overlap-5">
                        <img className="screenshot" alt="Screenshot"
                             src="/static/img/screenshot-2024-06-06-at-11-04-34-am.png"/>
                        <img className="rectangle-6" alt="Rectangle" src="/static/img/rectangle-42.png"/>
                        <img className="TINY-TOES" alt="Tiny TOES" src="/static/img/tiny-toes.png"/>
                        <img className="outdoor-preschool" alt="Outdoor preschool"
                             src="/static/img/outdoor-preschool-program.png"/>
                        <img className="line-3" alt="Line" src="/static/img/line-3-2.png"/>
                        <img className="MARIN" alt="Marin" src="/static/img/marin.png"/>
                    </div>
                    <div className="overlap-6">
                        <img className="things-we-love" alt="Things we love" src="/static/img/things-we-love.png"/>
                        <img className="group-3" alt="Group" src="/static/img/group-17-1x-png.png"/>
                    </div>
                    <img className="your-online" alt="Your online" src="/static/img/your-online-community-groups.png"/>
                    <div className="overlap-7">
                        <img className="open-the-list" alt="Open the list" src="/static/img/open-the-list.png"/>
                        <img className="polygon" alt="Polygon" src="/static/img/polygon-1.png"/>
                    </div>
                    <img className="line-4" alt="Line" src="/static/img/line-3-2.png"/>
                    <img className="line-5" alt="Line" src="/static/img/line-3-2.png"/>
                    <img className="join-a-topic-group" alt="Join a topic group"
                         src="/static/img/join-a-topic-group-or-meet-up.png"/>
                </div>
            </div>
        </TheMListContainer>

    );
};

export default CommunityPage;
